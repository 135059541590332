<div class="container">
  <div class="content-box">
    <ng-container *ngIf="user.mail_verified; else mailPending">
      <div class="ag-header sticky">
        <div class="ag-header-title">
          <h3>{{ 'GLOBAL.MY_COMPANIES'|translate }}</h3>
        </div>
        <div class="ag-header-actions">
          <add-phone [user]="user"></add-phone>
        </div>
      </div>

      <ng-container *ngIf="!loading; else loadingIndicator">
        <div class="row">
          <div class="col-xs-12">
            <p *ngIf="!atLeastOneCompanyActive && accounts?.length" [innerHtml]="'COMPANIES.ACTIVATION_HELP'|translate"></p>
            <p *ngIf="atLeastOneCompanyActive" [innerHtml]="'COMPANIES.SELECT_HELP'|translate">
            </p>
          </div>
        </div>
        <div class="row" *ngIf="accounts.length > 8">
          <div class="col-sm-6">
            <form>
              <div class="form-group">
                <!-- <label for="filterKey"><span class="material-symbols-rounded">search</span>&nbsp;</label> -->
                <input [(ngModel)]="filterKey" name="filterKey"
                autocomplete="off"
                type="text" class="form-control input-lg"
                id="filterKey" placeholder="{{ 'MESSENGER_CENTER.SEARCH_PLACEHOLDER'|translate }}">
              </div>
            </form>
          </div>
        </div>
        <div class="row stretch-items">
          <!-- Company cards -->
          <div class="col-sm-4 col-md-3" *ngFor="let account of accounts"
          [class.hidden]="filterKey?.length && 
          !(((account.company.name + '|' + account.company.activity.name + '|' + (account.company.activity.label || ''))|latinise)|test: (filterKey|latinise): 'gi')">
            <div class="card" (click)="set(account.company)"
            [class.disabled]="!user.mail_verified || !account.company.activation_level.id"
            [class.basic]="account.company.activation_level.id === 1">
              <div *ngIf="account.company.activation_level.id === 1" class="ribbon ribbon-right ribbon-xs ribbon-warning">
                <span>{{ 'COMPANIES.BASIC'|translate }}</span>
              </div>
              <div *ngIf="!account.company.activation_level.id" class="ribbon ribbon-right ribbon-xs ribbon-warning">
                <span>{{ 'GLOBAL.REVIEWING'|translate }}</span>
              </div>
              <div *ngIf="account.company.activation_level.id === 2 && account.parent" class="ribbon ribbon-right ribbon-xs ribbon-info">
                <span>{{ 'GLOBAL.REPRESENTATIVE'|translate }}</span>
              </div>
        
              <div class="header">
                <div class="logo-circle image shadow" placement="top">
                  <ng-container *ngIf="account.company.market; let market">
                    <ng-template #mrktTemplate><b>{{ market.name }}</b><div class="truncate small">{{ market.attributes.description }}</div></ng-template>
                    <div class="market shadow"
                    *ngIf="marketsPresent.length > 1"
                    [tooltip]="mrktTemplate"
                    placement="bottom"
                    [style.backgroundImage]="market.attributes.logo|trustStyleUrl"></div>
                  </ng-container>
                  <div class="logo" [style.backgroundImage]="account.company.logo|trustStyleUrl"></div>
                </div>
              </div>
              <div class="body">
                <h3 title="{{ account.company.name }}"
                [innerHtml]="account.company.name|highlighter:filterKey"></h3>
                <div class="activity truncate small text-center text-muted"
                [innerHtml]="(account.company.activity.label || account.company.activity.name)|highlighter:filterKey"></div>
              </div>
            </div>
          </div>
          
          <!-- Add card -->
          <div class="col-sm-4 col-md-3">
            <div class="card add" routerLink="/select-market">
              <div class="dashed">
                <div class="header">
                  <div class="logo-circle image shadow" placement="top">
                    <div class="logo">
                      <i class="material-symbols-rounded">add</i>
                    </div>
                  </div>
                </div>
                <div class="body">
                  <h3>{{ 'GLOBAL.ADD'|translate }}...</h3>
                  <div class="activity truncate small text-center">{{ 'COMPANIES.ADD_COMPANY'|translate }}</div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="col-sm-8 col-md-9" *ngIf="accounts?.length === 0">
            <h4 [innerHtml]="'COMPANIES.WELCOME'|translate: {name: user.name}"></h4>
        
            <easy-steps [steps]="[
              {
                title: 'COMPANIES.NO_COMPANY'|translate,
                bullets: [
                  'COMPANIES.NO_COMPANY_1'|translate,
                  'COMPANIES.NO_COMPANY_2'|translate
                ]
              }
            ]" [footer]="'COMPANIES.ADD_COMPANY_HELP'|translate"></easy-steps>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #mailPending>
  <div class="no-data no-data-sm">
    <i class="material-symbols-rounded">pan_tool</i>
    <h4 [innerHtml]="'COMPANIES.WELCOME'|translate: {name: user.name}"></h4>
    <p [innerHtml]="'HEADER.VERIFICATION_PENDING'|translate"></p>
    <p class="small" [innerHtml]="'COMPANIES.VERIFY_EMAIL'|translate"></p>
    <button [disabled]="countDownValue > 0 || processing"
    (click)="sendConfirmationEmail()"
    type="button" class="btn btn-agree btn-lg">
      <span class="material-symbols-rounded">mail</span>
      {{ 'EMAIL.RESEND'|translate }}
    </button>

    <ng-container [counter]="secondsToReSend" [interval]="1000" (value)="countDownValue = $event">
      <h2><strong *ngIf="countDownValue > 0"> {{ countDownValue }} seg. </strong></h2>
    </ng-container>

    <div class="text-muted small" [innerHtml]="'COMPANIES.ADD_COMPANY_HELP'|translate"></div>
  </div>
</ng-template>

<ng-template #loadingIndicator>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>