import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { LoginService } from '../../services/login.service';

/**
 * [[User]] sign-up form.
 */
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  @ViewChild(NgForm, { static: true }) public form: NgForm;

  public availableLanguages: {
    name: string;
    slug: string;
  }[] = environment.languages;
  public sending = false;
  public user = {
    email: null,
    name: null,
    last_name: null,
    password: null,
    password_confirmation: null,
    invitation: null
  };
  public with_mail: boolean;

  private token: string;

  /** @ignore */
  constructor(
    private router: Router,
    private loginService: LoginService,
    /** @ignore */
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.loginService.logout(true);
    this.token = this.activatedRoute.snapshot.queryParams['activation_token'];
    if (this.token) {
      this.loginService.validateActivationToken(this.token).subscribe({
        next: user => {
          this.user.email = user.email;
          this.with_mail = true;
        },
        error: error => {
          // console.log('invalid token');
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      });
    } else {
      this.with_mail = false;
    }

    // Clear custom errors
    // TODO: directive or validator for custom errors?
    this.form.valueChanges.pipe(
      debounceTime(100)
    ).subscribe(() => {
      // Clear all errors from the entire form
      this.form.control.setErrors(null);

      // Clear the 'unique' error specifically from the 'email' control if it exists
      const emailControl = this.form.controls['email'];
      if (emailControl?.errors?.['unique']) {
        emailControl.setErrors(null);
      }
    });
  }

  /** Sign-up form submit. */
  public signUp(): void {
    this.sending = true;

    if (this.token) {
      this.user.invitation = {
        activation_token: this.token
      };
    }

    this.loginService.register(this.user).subscribe({
      next: login => {
        this.loginService.checkMailVerified();
        this.sending = false;
        this.router.navigate(['/companies']);
      },
      error: error => {
        this.sending = false;
        let message = error.error.message;
        if (message) {
          if (message.inputs && message.inputs.email && message.inputs.email.Unique) {

            this.form.controls.email.setErrors({
              "unique": true
            });

            this.form.control.setErrors({
              "not_unique_email": true
            });

          } else {

            this.form.control.setErrors({
              "remote": true
            });

          }
        } else {
          this.form.control.setErrors({
            "unknown": true
          });
        }
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  /** Language switch. */
  public updateLanguage(slug: string): void {
    this.translateService.use(slug);
  }
}
