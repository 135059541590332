import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ThemeService } from '../../services/theme.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ag-link-redirect',
  standalone: true,
  imports: [],
  template: ''
  // templateUrl: './link-redirect.component.html',
  // styleUrl: './link-redirect.component.scss'
})
export class LinkRedirectComponent implements OnInit {
  // Diccionario de redirecciones
  private linksMap: { [key: string]: string } = {
    'cookies': 'https://www.agree.ag/cookies/index.html',
    'privacy-ar': '/documents/privacy/ar',
    'privacy-fob': '/documents/privacy/fob',
    'privacy-mx': '/documents/privacy/mx',
    'privacy-mxcurp': '/documents/privacy/mxcurp',
    'privacy-py': '/documents/privacy/py',
    'privacy': '/privacy',
    'terms-ar': '/documents/terms/ar',
    'terms-fob': '/documents/terms/fob',
    'terms-mx': '/documents/terms/mx',
    'terms-mxcurp': '/documents/terms/mxcurp',
    'terms-py': '/documents/terms/py',
    'terms': '/terms',
  };

  constructor(
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const key = params.get('key');

      // Innteto obtener link del Theme, sino uso link default
      const targetUrl = key ?
        (this.themeService.theme.languages?.links?.[key] || this.linksMap[key]) : null;

      if (targetUrl) {
        // window.location.href = targetUrl; 
        // location.replace evita que la ruta actual que en el historial
        this.document.location.replace(targetUrl); // Redirección externa
      } else {
        this.router.navigate(['/not-found']); // Redirección a una página 404 si no existe
      }
    });
  }
}
