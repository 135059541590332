import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { instanceToInstance } from 'class-transformer';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Login } from '../../auth/models/login.model';
import { NotificationChannel, User } from '../../auth/models/user.model';
import { LoginService } from '../../auth/services/login.service';
import { CacheService } from '../../services/cache.service';
import { ComponentCommService } from '../../services/component-comm.service';
import { DataDogLoggerService } from '../../services/data-dog-logger.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('infoTemplate') private readonly infoTemplate: TemplateRef<any>;
  @ViewChild('channelsTemplate') private readonly channelsTemplate: TemplateRef<any>;
  @ViewChild('passwordTemplate') private readonly passwordTemplate: TemplateRef<any>;

  public userChannels: NotificationChannel[];
  public errorMessage: string;
  public languages: any[];
  public successMessage: string;
  public sections: ProfileSection[];
  public selectedSection: ProfileSection;
  public password: {
    username?: string,
    password?: string,
    newPassword?: string,
    id?: number
  };

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public sectionSlug: string;
  /** Current [[User]]. */
  public user: User;
  public userTemp: User;

  private subscriptions: Subscription[] = [];

  constructor(
    private loginService: LoginService,
    private componentComm: ComponentCommService,
    private router: Router,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.languages = environment.languages;
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'GLOBAL.USER_PROFILE' });

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
      if (!this.user) this.router.navigate(['login']);
      else this.reset();
    }));
  }

  ngAfterViewInit(): void {
    this.sections = [
      { id: 1, title: 'USER_PROFILE.PROFILE_TITLE', slug: 'info', template: this.infoTemplate },
      { id: 2, title: 'USER_PROFILE.NOTIFICATIONS', slug: 'channels', template: this.channelsTemplate },
      { id: 3, title: 'USER_PROFILE.SECURITY_TITLE', slug: 'password', template: this.passwordTemplate }
    ];

    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      this.sectionSlug = params.get('section');
      this.setSection();
    }));
  }

  private reset(): void {
    this.password = {};
    if (this.user) {
      this.password.username = this.user.email;
      this.password.id = this.user.id;
    }

    this.userTemp = instanceToInstance(this.user);

    this.errorMessage = undefined;
    this.successMessage = undefined;

    if (this.selectedSection && this.selectedSection.slug === 'channels' && !this.userChannels) {
      this.subscriptions.push(this.loginService.getChannels().subscribe(channels => {
        this.userChannels = channels.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }));
    }
  }

  public profileUpdate(): void {
    this.processing = true;
    this.errorMessage = undefined;
    this.successMessage = undefined;

    this.loginService.updateUser(this.userTemp).subscribe({
      next: user => {
        this.user = this.userTemp;

        this.translateService.use(this.user.language);
        this.successMessage = 'USER_PROFILE.PROFILE_UPDATED';

        let login: Login = this.cacheService.get('login');
        login.user.name = this.user.name;
        login.user.last_name = this.user.last_name;
        login.user.language = this.user.language;
        this.cacheService.set('login', login, 60 * 24 * 365);

        this.processing = false;
      },
      error: error => {
        this.errorMessage = error;
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  public changePassword(): void {
    this.processing = true;
    this.errorMessage = undefined;
    this.successMessage = undefined;

    this.loginService.updatePassword(this.password).subscribe({
      next: change => {
        // this.reset();
        this.successMessage = 'USER_PROFILE.PASSWORD.UPDATED';
        this.processing = false;
      },
      error: error => {
        this.errorMessage = 'USER_PROFILE.PASSWORD.ERROR';
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  private setSection(): void {
    // Set current collection
    this.selectedSection = this.sections.find(col => col.slug === this.sectionSlug);

    if (!this.selectedSection) {
      // Default to first
      this.navigateTo(this.sections[0]);
    } else this.reset();
  }

  public navigateTo(section: ProfileSection): void {
    if (!this.selectedSection ||
      this.selectedSection.slug !== section.slug) {

      setTimeout(() => {
        this.router.navigate(['/user', section.slug], {
          queryParams: section.queryParams || {}
        });
      });
    }
  }

  public updateChannels(): void {
    this.processing = true;
    this.subscriptions.push(this.loginService.updateChannels(this.userChannels).subscribe(channels => {
      this.processing = false;
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

class ProfileSection {
  id: number;
  title: string;
  slug: string;
  template: TemplateRef<any>;
  queryParams?: any;
}