export default {
  GLOBAL: {
    ACCEPT: 'Aceptar',
    ACTIONS: "Acciones",
    ADD_COMPANY: 'Agregar Empresa',
    ADD: 'Agregar',
    ADVANCED_OPTIONS: 'Opciones avanzadas...',
    APP_ERROR_401: '<b>Tu sesión expiró...</b><br>Es necesario que vuelvas a autenticarte.',
    APP_ERROR_403: '<b>No tienes permisos para esa acción...</b><br>Haz click aquí para <b>volver a tus Empresas</b>. Si vuelve a pasar, contacta nuestro equipo de soporte.',
    APP_ERROR_404: '<b>Ocurrió un error...</b><br>Haz click aquí para <b>refrescar la página</b>. Si vuelve a pasar, contacta nuestro equipo de soporte.',
    APP_ERROR_DEFAULT: '<b>Ocurrió un error...</b><br>Haz click aquí para contactar a nuestro equipo de soporte.',
    APPROVE: 'Aprobar',
    APPROVED: 'Aprobadas',
    ARE_YOU_SURE: '¿Estás seguro?',
    AUCTION: 'Subasta',
    BACK: 'Volver',
    BARTER: 'Canje',
    BID: 'Compra',
    BROKER: 'Corredor',
    CANCEL: 'Cancelar',
    CANCELED: 'Anulado',
    CANNOT_UNDONE: 'Esta operación no se puede deshacer.',
    CERTIFICATE_DEPOSIT: 'Certificado de Depósito',
    CERTIFICATES: 'Certificados',
    CLEAR_SELECTION: 'Limpiar selección',
    CLEAR: 'Limpiar',
    COLLECTION: 'Colección <span class="currency">[{{length}} elemento(s)]</span>',
    COMMERCIAL_MANAGER_HELP: 'Asignar productos y definir límites de operaciones.',
    COMMERCIAL_MANAGER: 'Administración de comerciales',
    COMMISSION: 'Comisión',
    COMPANY_ADMIN: 'Administración de Empresas',
    COMPANY: 'Empresa',
    CONFIG_ASSISTANT: 'Asistente de configuración',
    CONFIRM: 'Confirmar',
    CONTRACT: 'Contrato',
    CONTRACTS: 'Contratos',
    COPIED_CLIPBOARD: 'Copiado al portapapeles!',
    COPY_CLIPBOARD: 'Copiar al portapapeles',
    COUNTERORDER: 'Contraorden',
    COUNTERPART: 'Contraparte',
    COUNTERPARTS: 'Contrapartes',
    COUNTRY_PORT: 'País / Puerto',
    CREATE: 'Crear',
    CROP_YEAR_PLACEHOLDER: 'Selecciona el período',
    CROP_YEAR: 'Cosecha',
    CUSTOMER: 'Cliente',
    DATA_ERROR_AVAILABLE: 'Ocurrió un error al cargar la información.<br>Es posible que la información aún no este disponible, vuelve a intentar en unos minutos.',
    DATA_ERROR_PERMISSION: 'Ocurrió un error al cargar la información.<br>Es posible que no tengas permisos para visualizar estos datos.',
    DATE: 'Fecha',
    DATE_FORMAT: {
      XS: 'd MMM',
      SM: 'dd/MM/yy',
      RG: 'dd/MM/yyyy',
      MD: 'dd/MMM/yy',
      LG: 'dd/MMM/yyyy'
    },
    DATE_LONG: 'EEEE, d \'de\' MMMM \'de\' yyyy',
    DATE_PLACEHOLDER: 'dd/mm/aaaa',
    DELETE_ALL: 'Eliminar todo',
    DELIVERY_PERIOD: 'Período de entrega',
    DELIVERY_PLACES: 'Lugares de entrega',
    DELIVERY_TYPE_ABBR: 'Entrega',
    DELIVERY_TYPE: 'Tipo de entrega',
    DISAPPROVED: 'Desaprobadas',
    DISTRIBUTOR: 'Distribuidor',
    DOCUMENTS_LANGUAGE: 'Idioma de los documentos',
    DOWNLOAD: 'Descargar',
    DUE_DATE_ABBR: 'Venc.',
    DUE_DATE: 'Vencimiento',
    EDIT_ADDRESS: 'Editar domicilio legal de <b>{{company}}</b>',
    EDIT_COMPANY: 'Editar Empresa',
    EDIT: 'Editar',
    ENTER_COMPANY: 'Ingresa una Empresa...',
    ERROR_API: '<b>Uh!</b> Algo salió mal. Por favor, intenta de nuevo más tarde.',
    EXPORT_XLS_SUCCESS: 'La solicitud de exportación fue un éxito, pero la recolección de datos puede llevar un tiempo, por eso recibirás un correo con el archivo Excel descargable pronto. ¡Gracias por tu paciencia!',
    EXPORT_XLS: 'Exportar a XLS',
    EXTERNAL_REFERENCE_ABBR: 'Ref. Ext.',
    EXTERNAL_REFERENCE: 'Referencia Externa',
    FEES: 'Aranceles',
    FILES: 'Archivos',
    FIXING_CONDITIONS: 'Condiciones de fijación',
    FIXING_PERIOD: 'Período de fijación',
    FORM: 'Formulario',
    GENERATE_EXPORT: 'Generar exportación',
    GENERATE_REPORT: 'Generar reporte',
    GENERATE: 'Generar',
    HAVE_ACCOUNT_ALREADY: '¿Ya tienes cuenta?',
    IDENTITY_VALIDATION: 'Administración de Validaciones de Identidad',
    INTEGRATIONS: 'Integraciones',
    INVOICE_NUMBER: 'Nro. Factura',
    ISSUED_BY: 'Emisor',
    ISSUED: 'Emisión',
    KEY_NAME: 'Identificador',
    LOADING: 'Cargando datos...',
    MAIN_ACTIVITY: 'Actividad Principal',
    MARKET: 'Mercado',
    MESSENGER_CENTER: 'Centro de mensajes',
    MIRROR_CONTRACT: 'Espejo',
    MY_COMPANIES: 'Mis Empresas',
    NAME: 'Nombre',
    NEED_HELP: '¿Necesitas ayuda?',
    NEGOTIATED_VOLUME: 'Volumen negociado',
    NO_DATA: 'No se encuentran registros.',
    NOT_AVAILABLE_ABBR: 'N/D',
    NOTES: 'Datos de Empresas',
    OBSERVATIONS_ABBR: 'Obs.',
    OBSERVATIONS: 'Observaciones',
    OPERATION_ALERTS: 'Alertas de operaciones',
    OPTIONAL: 'Opcional',
    ORDER: 'Orden',
    ORDERS_ADMIN: 'Órdenes de compraventa',
    ORIGIN: 'Procedencia',
    PAYMENT_CONDITION: 'Condición de pago',
    PAYMENT_METHOD: 'Método de pago',
    PENDING: 'Pendientes',
    PERCENTAGE: 'Porcentaje',
    PERIOD: 'Período',
    PERMISSION_ADMIN: 'Administración de Permisos',
    PRICE: 'Precio',
    PRIVACY_POLICY: 'Políticas de Privacidad',
    PROCESSING: 'Procesando...',
    PRODUCT: 'Producto',
    QUALITY: 'Calidad',
    QUANTITY_ABBR: 'Cant.',
    QUANTITY: 'Cantidad',
    RANGE_PLACEHOLDER: 'dd/mm/aa - dd/mm/aa',
    RECEIVER: 'Destinatario',
    REFRESH_VIEW: 'Esta vista ha cambiado, haz click aquí para <b>refrescar la información</b>.',
    REJECT: 'Rechazar',
    REPRESENTATIVE: 'Representante',
    RETRY: 'Reintentar',
    REVIEWING: 'En revisión',
    SAVE_CHANGES: 'Guardar cambios',
    SAVE: 'Guardar',
    SELECT_PRODUCT: 'Selecciona un producto...',
    SELECT: 'Seleccionar...',
    SELL: 'Venta',
    SELLER_ADMIN: 'Gestión de contrapartes',
    SEND: 'Enviar',
    SHARED_FILES: 'Archivos compartidos',
    SOURCE: 'Fuente',
    SPECIFICATIONS: 'Especificaciones',
    STATE: 'Estado',
    STATUS: 'Estado',
    SUPPLIER: 'Proveedor de insumos/servicios',
    TABLE: 'Tabla <span class="currency">[{{length}} fila(s)]</span>',
    TEAM: 'Equipo',
    TEMPLATES_ADMIN_NOTIFICATION_ERROR_MSG: 'JSON incompleto o no válido, por favor, compruébelo.',
    TEMPLATES_ADMIN_NOTIFICATION_HEADER: 'Gestiona las plantillas de las notificaciones dentro de la plataforma.',
    TEMPLATES_ADMIN_NOTIFICATION_NEW: 'Nueva notificación',
    TEMPLATES_ADMIN: 'Administración de Templates',
    TEMPLATES: 'Plantillas de Órdenes',
    TERMS_AND_CONDITIONS: 'Términos y Condiciones',
    TOGGLE_HIDE: '<span class="hidden-sm hidden-xs">Ocultar </span><i class="material-symbols-rounded">expand_less</i>',
    TOGGLE_SHOW: '<span class="hidden-sm hidden-xs">Mostrar </span><i class="material-symbols-rounded">expand_more</i>',
    TOTAL: 'Total',
    TRADE: 'Negocio',
    TYPE: 'Tipo',
    UNDO: 'Deshacer',
    USER_MANUAL: 'Manual de Usuario',
    USER_PROFILE: 'Mi Perfil',
    VERSION: 'Versión',
    VIEW_MORE: 'Ver más',
    WALKTHROUGH: 'Guía de bienvenida',
    WELCOME: '<h2>Food Smart Trade</h2><h3>Bienvenido a la plataforma B2B que permite comercializar alimentos en el mercado global y doméstico</h3><p>Integramos y conectamos a toda la cadena comercial de manera fácil, transparente y segura</p>',
    WITHDRAW: 'Retirar',
    YES_ACCEPT: 'Si, aceptar',
    YES_APPROVE: 'Si, aprobar',
    YES_CONFIRM: 'Si, confirmar',
    YES_DELETE: 'Si, eliminar',
    YES_REJECT: 'Si, rechazar',
    YES_REMOVE: 'Si, quitar',
    YES_SEND: 'Si, enviar',
    YES_WITHDRAW: 'Si, retirar'
  },
  EMAIL: {
    EMAIL_SEND_ERROR: "Ha ocurrido un error al enviar el email.",
    EMAIL_SEND_SUCCESS: "El email ha sido enviado con éxito!",
    RESEND: "Reenviar email de Verificación",
  },
  WALKTHROUGH: {
    GENERAL: {
      INTRO_1: '<h5>Bienvenido a <b>Agree</b>!</h5><p>Antes de empezar, nos gustaría indicarte los principales elementos de nuestra plataforma.</p><p class="text-right">¿Comenzamos?...</p>',
      INTRO_2: '<h5>Menú del Usuario</h5><p>Contiene opciones relevantes a tu cuenta.</p><p>Desde aquí puedes editar tu perfil o seleccionar con cuál de tus Empresas operarás.</p>',
      INTRO_3: '<h5>El menú de Empresa</h5><p>Configura tu equipo, gestiona contrapartes, genera alertas de operaciones, configura lugares de entrega y selecciona las plantillas guardadas para crear una orden en segundos.</p>',
      INTRO_4: '<h5>Notificaciones</h5><p>Cada vez que hubiera alguna acción requerida, te llegará un mensaje. También recibirás un correo electrónico.</p>',
      INTRO_5: '<h5>Barra de navegación principal</h5><p>Te permite acceder a las distintas secciones de Agree.</p>',
      INTRO_6: '<h5>Botón de creación</h5><p>Por último, ¡el botón más importante!</p><p><b>Crea tus Órdenes</b> de compra o venta desde aquí.</p>',
      INTRO_7: '<h5>¡Eso es todo!</h5><p>De vez en cuando, vamos a ir comunicándote novedades en el producto, como nuevas funcionalidades y mejoras.</p><p>Ante cualquier duda puedes escribirnos a <a href="mailto:info@agree.ag">info@agree.ag</a></p>'
    },
    MARKET: {
      INTRO_1: '<h5>Resumen de Mercado</h5><p>Mira los precios con las puntas del mercado, punta <span class="buy">compradora</span> con el precio más alto para esa posición, punta <span class="sell">vendedora</span> con el precio más bajo y el último valor negociado.</p>',
      INTRO_2: '<h5>Filtra Órdenes</h5><p>Seleccionando el precio podrás ver sólo las Órdenes de compra o venta para esa posición.</p>',
      INTRO_3: '<h5>Filtra lugares de entrega</h5><p>Podrás ver el resumen por zonas o por localidades.</p>',
      INTRO_4: '<h5>Disponible y diferido</h5><p>La primer columna corresponde a la posición disponible, las siguientes son los meses que corresponen a posiciones diferidas.</p>'
    },
    WORKING_ORDERS: {
      INTRO_1: '<h5>Órdenes de Compraventa</h5><p>Busca la orden que te interesa, seleccionala y puedes negociar o <span class="buy">comprar</span> y <span class="sell">vender</span>.</p>'
    },
    MY_OPERATIONS: {
      INTRO_1: '<h5>Mis Operaciones</h5><p>Este es tu tablero de control donde podrás gestionar tus Órdenes: podrás ver tus acciones pendientes y el estado de cada una de tus negociaciones.</p>',
      INTRO_2: '<h5>Documentos del negocio</h5><p>Finalizada cada negociación podrás <b>descargar el historial</b> que incluye todas las acciones realizadas, incluyendo responsable y fecha/hora.</p><p>Y al anotarse, podrás <b>descargar la Confirmación del negocio o el Contrato</b> y observar los términos acordados entre las partes.</p>'
    },
    TRADED_ORDERS: {
      INTRO_1: '<h5>Órdenes Anotadas</h5><p>Podrás observar en vivo las <b>Órdenes anotadas en el canal abierto</b>, sin hacer mención de las contrapartes.</p><p>Las Órdenes anotadas en el canal privado no se publican.</p>'
    },
    COMPANY_TRADED_ORDERS: {
      INTRO_1: '<h5>Negocios de mi Empresa</h5><p>Podrás observar todas las Órdenes anotadas por tu Empresa, con acceso a la <b>Confirmación del Negocio</b> e <b>Historial del Negocio</b>.</p>'
    }
  },
  HEADER: {
    BASIC_ACTIVATION: '<b>Tu Empresa aún no puede operar.</b> Es probable que necesitemos validar algunas cosas. Ponete en contacto con <a href="mailto:info@agree.ag">nuestro equipo de soporte</a>.',
    CREATE_AUCTION: 'Crear Subasta',
    CREATE_ORDER: 'Crear Orden',
    INVALID_PASSWORD_TOKEN: '<b>El link es inválido o ha expirado.</b>',
    LOGOUT: 'Cerrar sesión',
    NOTIFICATIONS: 'Notificaciones',
    PASSWORD_RESET_SENT: '<b>Se ha enviado un mensaje</b> a tu email para que puedas cambiar tu contraseña.',
    PENDING_REQUESTS: 'Solicitudes pendientes',
    SELECT_LANGUAGE: 'Idioma',
    SUPER_ADMIN_HEADER: 'Super Admin',
    VERIFICATION_PENDING: '<b>¡Verificación pendiente!</b>\
      Se ha enviado un email de verificación a tu dirección de correo.\
      Por favor, confirma tu dirección para poder continuar.',
    VERIFICATION_SUCCESSFUL: '<b>¡Verificación exitosa!</b>\
      Tu email ha sido verificado, ya puedes operar.',
    VERIFICATION_FAILED: '<b>¡No se pudo verificar tu email!</b> Por favor, ponte en contacto con <a href="mailto:info@agree.ag">nuestro equipo de soporte</a>.',
    PASSWORD_UPDATED: '<b>¡Contraseña actualizada correctamente!</b>\
      Puedes proceder a ingresar al sistema.'
  },
  LOGIN: {
    DONT_HAVE_ACCOUNT: '<span class="hidden-xs">¿No tienes cuenta?&nbsp;&nbsp;</span><b>Regístrate Gratis</b>',
    EMAIL: 'Dirección de email',
    ERROR: 'El email y la contraseña que ingresaste <b>son incorrectos</b>. <a href="/reset-password">¿Olvidaste tu contraseña?</a>',
    FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    GOOGLE: 'Inicia sesión con Google',
    HEADING: 'Ingresa tu <b>email</b> y <b>contraseña</b>',
    LOGIN: 'Ingresar',
    OTHER_OPTIONS: 'Otras opciones',
    PASSWORD: 'Contraseña'
  },
  SIGNUP: {
    CREATE_ACCOUNT: 'Crear cuenta',
    DISCLAIMER: '<b>¡Importante!</b> Al registrarte, aceptas los <a href="/links/terms">Términos y Condiciones</a> y las <a href="/links/privacy">Políticas de Privacidad</a>.',
    EMAIL_EXISTS_ERROR: 'El email ingresado ya existe.',
    EMAIL: 'Dirección de email',
    HAVE_ACCOUNT_ALREADY: '<span class="hidden-xs">¿Ya ténes cuenta?&nbsp;&nbsp;</span><b>Inicia sesión</b>',
    HEADING: 'Regístrate <b>Gratis</b>',
    HELP_BLOCK: 'Completa tus datos, <b>registrarte no tiene ningún costo</b>.<br><small>Todos los campos son obligatorios.</small>',
    INVALID_FORM_ERROR: 'Corrige tus datos y vuelve a intentarlo',
    LAST_NAME: 'Apellido',
    LAST_NAMES: 'Apellidos',
    PASSWORD_CONFIRM: 'Confirmación de contraseña',
    PASSWORD_HELP: '<b>Debe contener al menos</b> 8 caracteres, 1 letra mayúscula, 1 letra minúscula y 1 número.',
    PASSWORD: 'Contraseña',
    UNKNOWN_ERROR: 'Ocurrió un error, intenta más tarde.'
  },
  PASSWORD_RESET: {
    HEADING: 'Recuperar contraseña',
    HELP_BLOCK: 'Ingresa tu dirección de email y en unos minutos recibirás un enlace para crear una nueva contraseña.',
    EMAIL: 'Dirección de email',
    EMAIL_VERIFICATION_ERROR_0: '¡Tu email aún no ha sido verificado!',
    EMAIL_VERIFICATION_ERROR_1: 'Haz click',
    EMAIL_VERIFICATION_ERROR_2: 'aquí',
    EMAIL_VERIFICATION_ERROR_3: 'para enviar un nuevo email de verificación.'
  },
  UPDATE_PASSWORD: {
    EXPIRED_TOKEN: 'El token es inválido o ha expirado.',
    HELP: 'Ingresa tu nueva contraseña.',
    LABEL_CONFIRM: 'Ingresa nuevamente tu contraseña',
    LABEL_NEW: 'Nueva contraseña',
    PLACEHOLDER_CONFIRM: 'Ingresa nuevamente tu contraseña',
    PLACEHOLDER_NEW: 'Nueva contraseña',
    TITLE: 'Actualizar contraseña',
    UPDATE: 'Actualizar'
  },
  COMPANIES: {
    NO_COMPANY: 'Aún no perteneces a ninguna Empresa. Tienes dos maneras de unirte a una:',
    NO_COMPANY_1: 'Si tu Empresa aún <b class="text-danger">no está dada de alta</b>, regístrala haciendo click en <b>Agregar...</b>',
    NO_COMPANY_2: 'Si tu Empresa <b class="text-success">ya está registrada</b>, pidele al Administrador que te <b>sume al equipo</b>',
    ADD_COMPANY: 'Registra una nueva Empresa',
    ADD_COMPANY_HELP: 'Si tienes dudas, contacta a <a href=\'mailto:info@agree.ag\'>nuestro equipo de soporte</a>.',
    VERIFY_EMAIL: 'Recuerda revisar tu carpeta de correo no deseado. Si no recibiste ningún correo, puedes probar reenviarlo nuevamente.',
    ACTIVATION_HELP: 'Muchas gracias por haber registrado tu Empresa. En los próximos días, te contactaremos para informarte los pasos a seguir.',
    SELECT_HELP: 'Selecciona la Empresa con la que quieres operar.',
    BASIC: 'Pre-activada',
    WELCOME: 'Bienvenido, <b class="text-capitalize">{{name}}</b>!'
  },
  SELECT_MARKET: {
    HEADING: 'Selecciona el Mercado donde tu Empresa operará.',
    HEADING_TYPE: 'Selecciona el tipo de Mercado donde tu Empresa operará.'
  },
  REGISTER_COMPANY: {
    AUTHORIZATION: 'Poder de los firmantes',
    CITY: 'Ciudad',
    COUNTRY: 'País',
    ERROR_MESSAGE: 'Hubo un error, vuelve a intentar más tarde.',
    FILE_DESCRIPTION: 'Sube una imagen legible o un archivo PDF.',
    FISCAL_IDENTIFICATION: 'Identificación Fiscal',
    IDENTIFICATION: 'Copia del {{documento}} del autorizante',
    LEGAL_ADDRESS: 'Domicilio legal',
    LOGO_DESCRIPTION: 'Opcional, será usado como imagen de la Empresa. Sube una imagen cuadrada de 140px o más.',
    LOGO: 'Logo de la Empresa',
    MY_POSITION: 'Mi cargo en la Empresa',
    NAME_PLACEHOLDER: 'Nombre de la Empresa',
    NAME: 'Razón social',
    NAMES: 'Nombres',
    FIRST_SURNAME: 'Apellido paterno',
    SECOND_SURNAME: 'Apellido materno',
    POSITION_DESCRIPTION: 'El registro de la persona jurídica debe ser presentado por un Representante Legal o cualquier otra persona con capacidad legal.',
    POSITION: 'Cargo',
    POSTAL_CODE: 'Código Postal',
    STATE: 'Estado / Provincia',
    STATUTE: 'Estatuto de la sociedad',
    STREET: 'Calle y número, oficina',
    TAX_CERTIFICATE_HELP: 'Subir PDF emitido por SAT.',
    TAX_CERTIFICATE: 'Constancia de Situación Fiscal',
    TELEPHONE: 'Teléfono'
  },
  FILE_EXTRACT: {
    AND_MORE: '...y más.',
    DESCRIPTION: 'Extrae datos de tus documentos con la inteligencia artificial.',
    DISCLAIMER: 'Es importante validar los datos obtenidos. El modelo puede identificar más de un valor para cada campo.',
    EXTRACT: 'Extraer datos',
    EXTRACTING: 'Extrayendo...',
    FIELDS: 'Campos a extraer:',
    SUGGESTIONS: 'Sugerencias:'
  },
  FILE_INPUT: {
    AVAILABLE: 'Archivos disponibles',
    DROP_HERE: 'Arrastra y suelta archivos aquí...',
    GENERATING: 'Generando archivos...',
    MULIPLE_SELECTED: 'Archivos seleccionados',
    OPEN_FILE: 'Ver archivo...',
    PREVIEW: 'Vista previa...',
    REJECTED: 'Los siguientes archivos no tienen el formato o peso admitidos: <b>{{files}}</b>.',
    SELECT_FILE: 'Seleccionar archivo',
    SELECT_FILES: 'Seleccionar archivo(s)',
    SELECTED: 'Archivo seleccionado',
    UPLOAD_FILE: 'Sube un archivo...',
    UPLOAD_FILES: 'Sube archivos...',
    UPLOADING: 'Subiendo...',
    DELETE_CONFIRM: {
      TITLE: 'Eliminar archivo',
      MODAL_TITLE: '¿Estás seguro de eliminar este archivo?'
    }
  },
  COMPANY: {
    BARTERS: 'Canjes',
    COMPANY_TRADED_ORDERS: 'Negocios de mi Empresa',
    EXECUTION: 'Ejecución',
    FINTECH: 'Financiamiento',
    IMPORTED_DATA: 'Datos importados',
    MARKET_PRICES: 'Precios de Mercado',
    MARKET_SUMMARY: 'Resumen de mercado',
    MY_OPERATIONS: 'Mis operaciones',
    NOT_LISTED: '¿No encuentras la Empresa?',
    PREORDERS: 'Pre-órdenes',
    SIGNATURES: 'Firmas',
    SLOTS: 'Cupos',
    STATISTICS: 'Estadísticas',
    TRADED_ORDERS: 'Órdenes anotadas',
    WORKING_ORDERS: 'Órdenes de compraventa'
  },
  COMPANY_SETUP: {
    CERTIFICATES_HELP: 'Administra los Certificados de gestión y producto de tu Empresa.',
    COMPANY_LOCATIONS_HELP: 'Define los lugares de entrega para tu Empresa.',
    HEADING: 'Asistente de configuración',
    INTEGRATIONS_HELP: 'Conéctate mediante API.',
    NOTES_HELP: 'Gestiona información de otras Empresas',
    SELECT_HELP: 'Selecciona alguna de las opciones a continuación.',
    SELLER_ADMIN_HELP: 'Aprueba con qué Empresas quieres hacer negocios.',
    TEAM_HELP: 'Crea Usuarios para tu Empresa y asígnales distintos permisos.',
    WELCOME_1: '<b>Bienvenido!</b> Antes de operar es necesario configurar algunos aspectos de tu Empresa.',
    WELCOME_2: 'Siempre podrás volver y modificar estas opciones. Es importante mantener esta información actualizada.'
  },
  ORDER: {
    CREATE_CERTIFICATE: 'Crear Certificado',
    DEPOSIT_LOCATION: 'Lugar de depósito',
    NETWORK_ONLY: 'Sólo para la Red',
    NEW_CERTIFICATE: 'Nuevo Certificado de Depósito',
    NO_RECORDS: 'Aún no se registran órdenes.', // 'La plataforma aún no registra movimientos.',
    OWN_PRODUCED: 'Es producción propia (primaria)',
    PRIVATE: 'Orden privada',
    PRODUCTION: 'Producción'
  },
  AUCTION_FORM: {
    HEADING: 'Nueva Subasta',
    AUTHORIZED_BUYERS: {
      HELP: 'Los compradores que selecciones son los únicos que podrán inscribirse a la Subasta.'
    },
    TYPE: {
      HELP_0: 'De acuerdo a tu actividad, sólo puedes publicar Subastas de ',
      HELP_1: '.',
      BUY_HELP: '<b>Subasta inversa</b> o reversa, los vendedores compiten ofreciendo precios cada vez más bajos.'
    },
    SCOPE: {
      MY_NETWORK_HELP: 'Las Subastas publicadas en <b>Mi Red</b> son visibles sólo para las Empresas que intergran <a href="/company/{{companyId}}/network?order_by=-verification_date">la Red de tu Empresa</a>.',
      OPEN_HELP: 'Las Subastas en el <b>canal abierto</b> son visibles para todos, pero sólo las contrapartes autorizadas pueden inscribirse.<br>Una vez anotada, la transacción figurará en la sección <b>Órdenes Anotadas</b>, sin hacer mención de las contrapartes.',
      PRIVATE_HELP: 'Las Subastas en el <b>canal privado</b> son invisibles para el mercado, sólo son visibles para las contrapartes que invites.'
    },
    DATE: {
      DATE: 'Fecha de la Subasta',
      DURATION: 'Duración',
      DURATION_HELP: 'Una vez iniciada y recibida la primera oferta, <b>la Subasta no puede retirarse hasta que se agote el tiempo</b>. Las Subastas deben terminar antes del cierre del día del mercado.',
      DURATION_PLACEHOLDER: 'Selecciona una duración',
      EXTEND: 'Extender la duración al menos un minuto de la última oferta.',
      EXTEND_HELP: 'Esta extensión <b>se repite hasta que no se registren ofertas</b> de último minuto.',
      MINUTES: 'minutos',
      TIME: 'Hora de inicio',
      TIME_HELP: 'Hora para mercado doméstico {{market}}. Los interesados pueden inscribirse hasta la hora especificada.'
    },
    PRICE: {
      MAX: 'Oferta máxima',
      MIN: 'Oferta mínima'
    },
    STEP: {
      LABEL_BUY: 'Intervalo entre ofertas',
      LABEL_SELL: 'Intervalo entre ofertas',
      BUY_HELP: 'Valor mínimo de decremento entre ofertas.',
      SELL_HELP: 'Valor mínimo de incremento entre ofertas.'
    }
  },
  ORDER_FORM: {
    EDITING: 'Editando',
    HEADING: 'Nueva Orden',
    NO_COMPANY_LOCATIONS: 'Tu Empresa <b>no tiene definidos lugares de entrega</b> para la compra. <a class="alert-link" href="/company/{{companyId}}/locations">Haz click aquí</a> para agregar un lugar.',
    PREVIEW: 'Previsualizar',
    PUBLISHING: 'Publicando...',
    COMPANY_REPRESENTED_BY: "Empresas representadas por el corredor",
    TYPE: {
      BOOK_HELP_1: 'Cualquiera de las contrapartes autorizadas que definas podrán anotar directamente.',
      BOOK_HELP_2: 'Cualquier Empresa podrá anotar directamente.',
      BOOK_HELP: 'Las Órdenes de <span class="{{class}}">{{type}}</span> <b>son en firme</b>.',
      HELP_0: 'De acuerdo a tu actividad, sólo puedes publicar Órdenes de',
      HELP_1: '.',
      PREBOOK_HELP: 'En las Órdenes de <span class="{{class}}">{{type}}</span>, las contrapartes sólo podrán <b>solicitarte un pre-anote</b>, el cuál deberás aceptar para anotar.'
    },
    DELIVERY_TYPE: {
      PLACEHOLDER: 'Selecciona un tipo de entrega'
    },
    BROKER: {
      HELP: 'El corredor recibirá una <b>Pre-orden</b> y el puede publicarla, agruparla junto a otras Pre-órdenes o rechazarla.',
      LABEL: '¿Hay algún corredor involucrado?',
      PLACEHOLDER: 'Ninguno'
    },
    SCOPE: {
      LABEL: '¿Dónde se publicará la Orden?',
      MY_NETWORK_HELP: 'Las Órdenes publicadas en <b>Mi Red</b> son visibles sólo para las Empresas que intergran <a href="/company/{{companyId}}/network?order_by=-verification_date">la Red de tu Empresa</a>.',
      MY_NETWORK: 'Mi Red',
      NO_OPEN_HELP: 'Tu Empresa <b>no puede operar en el mercado Abierto</b>. Es probable que necesitemos validar algunas cosas. Si estás interesado, ponte en contacto con nosotros a <a href="mailto:info@agree.ag">info@agree.ag</a>.',
      OFFLINE_HELP: 'Una <b>operación offline</b> es declarativa, refleja un negocio que se confirmó fuera de la plataforma y <b>no requerirá confirmación</b> de la contraparte.',
      OFFLINE: 'Offline',
      OPEN_HELP: 'Las Órdenes en el <b>canal Abierto</b> son visibles para todos, pero sólo las contrapartes autorizadas podrán ver tu Empresa e interactuar con ella.<br>Una vez anotadas las Órdenes figurarán en la sección <b>Órdenes Anotadas</b>, sin hacer mención de las contrapartes.',
      OPEN: 'Abierto',
      PRIVATE_HELP: 'Las Órdenes en el <b>canal Privado</b> son invisibles para el mercado, sólo son visibles para las contrapartes que elijas.',
      PRIVATE: 'Privado'
    },
    PRODUCT: {
      NOT_LISTED: '¿No encuentras tu producto?',
      // NOT_SUSTAINABLE: 'No sustentable',
      SUSTAINABLE: 'Sustentable'
    },
    IMAGES: {
      LABEL: 'Imágenes',
      HELP: 'Selecciona hasta 20 imágenes.'
    },
    PRICE: {
      MAX_HELP: 'Valor <b>máximo</b> que estás dispuesto a pagar.',
      MAX: 'Precio máximo',
      MIN_HELP: 'Precio base. Valor <b>mínimo</b> que estás dispuesto a aceptar.',
      MIN: 'Precio mínimo'
    },
    QUANTITY: {
      FIXED_HELP: 'Por cantidad <b>Fija</b> la órden se cerrará con el primer anote.',
      HELP_0: 'Las Órdenes de ',
      HELP_1: ' pueden ser por cantidad <b>Fija</b> o <b>Máxima</b>.<br>',
      MAX_HELP: 'Por cantidad <b>Máxima</b> múltiples vendedores podrán "ofrecer" distintas cantidades para la misma Orden.'
    },
    PERIOD: {
      EXPIRATION_HELP_0: 'Si la Orden sigue activa después del',
      EXPIRATION_HELP_1: 'la plataforma automáticamente trasladará el período, respetando la duración del mismo.'
    },
    DELIVERY_LOCATION: {
      LABEL: 'Lugar(es) de entrega',
      LOADING: 'Buscando lugares...',
      TITLE: 'Agregar lugar de entrega'
    },
    AUTHORIZED_BUYERS: {
      ERROR_EMPTY_LOCATION: '<b>¡No hay compradores registrados en los lugares seleccionados!</b> Para poder publicar la Orden es necesario al menos un comprador autorizado.',
      ERROR_EMPTY_SELECTION: 'Selecciona al menos una Empresa como comprador autorizado.',
      HELP: 'Los compradores que selecciones son los únicos que podrán ver todos los detalles de tu Orden, anotarla o enviarte una Contraorden.',
      LABEL: 'Compradores autorizados',
      LOADING: 'Buscando compradores...'
    },
    LOADING_RATE: {
      LABEL: 'Tasa de carga',
      PLACEHOLDER: 'Tasa de carga'
    },
    ALTERNATIVE_LOADING_RATE: {
      LABEL: 'Tasa de carga alternativa'
    },
    PORT: {
      LABEL: 'Puerto',
      PLACEHOLDER: 'Selecciona un puerto'
    },
    BERTH: {
      LABEL: 'Muelle',
      PLACEHOLDER: 'Selecciona un muelle'
    },
    ALTERNATIVE_BERTH: {
      LABEL: 'Muelle alternativo',
      PLACEHOLDER: 'Muelle alternativo'
    },
    ALTERNATIVE_CONDITION: {
      LABEL: 'Condición alternativa'
    },
    ARBITRATION_CHAMBER: {
      LABEL: 'Cámara arbitral',
      PLACEHOLDER: 'Selecciona una cámara'
    },
    EXPIRATION_DATE: {
      LABEL: 'Fecha de expiración',
      HELP: 'Fecha en que la publicación será automaticamente retirada por el sistema.'
    },
    CONTRACT: {
      HELP: 'El siguiente Contrato es generado en función de los datos proporcionados. Puedes <b>editar</b>, <b>eliminar</b> o <b>agregar</b> nuevas cláusulas.',
      LANGUAGE_HELP: 'También puedes seleccionar el <b>idioma</b> con el cuál se generará la documentación relacionada.'
    },
    OBSERVATIONS: {
      HELP: 'En este campo, puedes agregar información adicional como por ejemplo cupos, comisiones, tipos de cambio u otros.'
    },
    CANCEL_CONFIRM: {
      BODY: 'Al cancelar la edición la Orden vuelve a estar disponible para operar sobre ella.',
      CONFIRM_BUTTON: 'Si, descartar cambios.',
      CONTINUE_BUTTON: 'Continuar editando',
      TITLE: '¿Quieres cancelar la edición de la Orden y descartar cambios?'
    }
  },
  PRIVATE_COMPANIES_SELECTOR: {
    HELP_BUY: 'Busca el <b>Comprador</b> con el que quieras operar. Recuerda que puedes agregar tantas Empresas como quieras.',
    HELP_SELL: 'Busca el <b>Vendedor</b> con el que quieras operar. Recuerda que puedes agregar tantas Empresas como quieras.'
  },
  ORDER_PREVIEW: {
    HELP: '<b>Vista previa.</b> Defina que variables desea identificar como "no negociables".',
    WARNING: 'Esta Orden se retirará automáticamente al final del día (23:59 horas GMT {{offset}}).',
    EXPIRATION: 'Esta Orden se retirará automáticamente el {{day}}, {{date}} de {{month}} de {{year}} (GMT {{offset}}).',
    BACK: {
      LABEL_0: 'Volver',
      LABEL_1: 'a modo edición',
      TITLE: 'Volver a modo edición'
    },
    PUBLISH: {
      LABEL: 'Publicar',
    },
    EDIT_CONFIRM: {
      TITLE: '¿Estás seguro de guardar los cambios?',
      BODY: 'De existir, todas las Contraórdenes abiertas de esta Orden se cancelarán automáticamnete.',
      CONFIRM_BUTTON: 'Si, guardar'
    }
  },
  NEGOTIABLE_GROUP: {
    NOT_NEGOTIABLE: 'Parámetro no negociable',
    DISCARD: 'Descartar cambios',
    PREVIOUS_VALUE: 'Valor anterior: ',
    CURRENT_VALUE: 'Valor actual: '
  },
  PROPOSAL: {
    ADJUSTED_FIELDS: {
      DELIVERYDATE: '<b>¡Atención!</b> La fecha de inicio estaba pasada y fue trasladada al día de hoy.'
    },
    PRICE: {
      PLACEHOLDER: '0,00',
      LABEL_MIN: 'Precio mínimo',
      LABEL_MAX: 'Precio máximo',
      OFFER_MIN: 'Oferta mínima',
      OFFER_MAX: 'Oferta máxima'
    },
    DELIVERY_LOCATIONS: {
      BUYER_OPTION: 'Opción comprador'
    },
    QUALITY: {
      PLACEHOLDER: 'No especifica'
    },
    ARBITRATION_CHAMBER: {
      LABEL: 'Cámara Arbitral',
      PLACEHOLDER: 'Selecciona una cámara'
    },
    CONTRACT: {
      DEVIATIONS: 'Existen desviaciones del contrato estándar',
      CLAUSE: {
        PLACEHOLDER: 'Escriba la cláusula...',
        ADD: 'Agregar cláusula',
        ADD_ABBR: 'Agregar',
        TITLE: 'Título',
        EDIT: 'Editar cláusula',
        EMPTY: '¡La cláusula no puede estar vacía!',
        DELETE: {
          LABEL: 'Eliminar cláusula',
          TITLE: '¿Estás seguro de eliminar esta cláusula?'
        }
      },
      TO_BE_COMPLETED: 'A completar por sistema'
    },
    LOADING_RATE: {
      LABEL: 'Tasa de carga',
      PLACEHOLDER: 'Tasa de carga'
    },
    PORT: {
      LABEL: 'Puerto',
      PLACEHOLDER: 'Selecciona un puerto'
    },
    BERTH: {
      LABEL: 'Muelle',
      PLACEHOLDER: 'Selecciona un muelle'
    }
  },
  ORDER_SUMMARY: {
    BROKER_BUYER: 'Corredor Comprador',
    BROKER_SELLER: 'Corredor Vendedor',
    BUYER: 'Comprador',
    DISCLOSE: 'Editar contrapartes...',
    DISCLOSE_CONFIRM: 'Revelar',
    DISCLOSE_HELP: 'Al revelar las Empresas representadas se notificará a la contraparte y se generarán nuevas versiones de los documentos asociados para incluir esta información.',
    DISCLOSURE_BUYERS: 'Divulgación de los Compradores',
    DISCLOSURE_SELLERS: 'Divulgación de los Vendedores',
    NO_DISCLOSURE: 'Sin divulgación',
    PAUSED: 'Pausada',
    SELLER: 'Vendedor'
  },
  AUCTION_VIEW: {
    PREAUCTION: 'Pre-Subasta',
    EDIT_CONFIRM: {
      TITLE: '¿Estás seguro de editar esta Subasta?',
      BODY_AUCTION: 'Mientras estés editando, la Subasta permanecerá pausada y oculta para el resto de las Empresas.',
      BODY_PREAUCTION: 'Mientras estés editando, la Subasta permanecerá pausada y el corredor no podrá procesarla.',
      CONFIRM_BUTTON: 'Si, editar'
    },
    CANCEL_CONFIRM: {
      TITLE: 'Retirar Subasta',
      TITLE_AUCTION: '¿Estás seguro de retirar esta Subasta?',
      TITLE_PREAUCTION: '¿Estás seguro de retirar esta Pre-subasta?',
      BODY_AUCTION: 'Todas las Empresas suscriptas serán notificadas.',
      BODY_PREAUCTION: ''
    },
    MODULE: {
      BID: 'Ofertar',
      BIDS: 'Oferta(s)',
      BIDS_HISTORY: 'Historial de ofertas',
      CLOSING: 'Finalizando...',
      COMPANY_ENROLLED: 'Tu Empresa ya está suscripta a esta Subasta.',
      COMPANY_ENROLLED_HELP: 'Te notificaremos cuando inicie la Subasta.',
      CURRENT_BID: 'Oferta actual',
      CURRENT_OFFER: 'Oferta actual',
      ENDED: 'Subasta finalizada.',
      ENROLLED: 'Empresas suscriptas:',
      ENROLL_BUTTON: 'Suscríbete a la Subasta',
      ENROLL_HELP: 'Puedes suscribirte hasta que inicie la Subasta. A partir de ese momento, esta Subasta sólo estará visiible para los participantes.',
      EXTENDED: 'La duración fue extendida {{seconds}} segundos debido a una oferta de último momento.',
      LAST_BID: 'Última Oferta',
      LAST_OFFER: 'Última Oferta',
      LIVE: 'Subasta en progreso!',
      MAXIMUM_BID: 'También puedes introducir tu oferta máxima:',
      MAXIMUM_BID_SET: 'Tienes definida una oferta máxima de:',
      MINIMUM_OFFER: 'También puedes introducir tu oferta mínima:',
      MINIMUM_OFFER_SET: 'Tienes definida una oferta mínima de:',
      NONE_ENROLLED: 'Aún no hay Empresas suscriptas.',
      OFFER: 'Ofertar',
      OFFERS: 'Oferta(s)',
      OFFERS_HISTORY: 'Historial de ofertas',
      PLACE_BID: 'Ofertar',
      PLACE_BID_HELP_0: 'La plataforma incrementará automáticamente tu oferta de a',
      PLACE_BID_HELP_1: 'contra otras ofertas hasta que se alcance el máximo.',
      PLACE_OFFER: 'Ofertar',
      PLACE_OFFER_HELP_0: 'La plataforma reducirá automáticamente tu oferta de a',
      PLACE_OFFER_HELP_1: 'contra otras ofertas hasta que se alcance el mínimo.',
      STARTING: 'Iniciando...',
      STEP_HELP_0: 'Esta Subasta tiene un intervalo entre ofertas de',
      STEP_HELP_1: '.',
      TIME_LEFT: 'Finaliza en:',
      TIME_TO_START: 'Inicia en:',
      WINNING_BID: 'Tu oferta es la mejor!',
      WINNING_BID_HELP: 'Te notificaremos si tu oferta es mejorada por otra Empresa.',
      WINNING_OFFER: 'Tu oferta es la mejor!',
      WINNING_OFFER_HELP: 'Te notificaremos si tu oferta es mejorada por otra Empresa.',
      WITHDRAWN: 'Subasta cancelada.',
      WON_AUCTION: 'Has ganado esta Subasta!',
      ENROLLED_MODAL: {
        TIME: 'Fecha y hora de suscripción',
        INVITATION_SENT: 'Invitación enviada'
      },
      HISTORY: {
        CURRENT: 'Actual',
        WINNER: 'Ganador',
        TIME: 'Fecha y hora',
        OFFER: 'Oferta',
        BID: 'Oferta',
        YOUR_OFFER: 'Tu oferta',
        YOUR_BID: 'Tu oferta',
        OFFER_OMITTED: 'Omitida. Existía una oferta anterior definida automáticamente.',
        BID_OMITTED: 'Omitida. Existía una oferta anterior definida automáticamente.'
      }
    },
    EDIT: 'Editar Subasta...',
    REPUBLISH: 'Republicar Subasta...'
  },
  ORDER_VIEW: {
    PREORDER: 'Pre-Orden',
    RELATED_PRODUCTS: 'Productos relacionados',
    EXPIRATION: 'Esta Orden se retirará el {{day}}, {{date}} de {{month}} de {{year}} (GMT {{offset}}).',
    BOOKED_ALERT: {
      TEXT_0: 'Atención!',
      TEXT_1: 'Ya se anotaron',
      TEXT_2: 'de las',
      TEXT_3: 'definidas para esta Orden.',
      WITHDRAW: '¿Quieres retirarla?'
    },
    EDIT_CONFIRM: {
      TITLE: '¿Estás seguro de editar esta Orden?',
      BODY_ORDER: 'Mientras estés editando, la Orden permanecerá pausada y oculta para el resto de las Empresas.',
      BODY_PREORDER: 'Mientras estés editando, la Orden permanecerá pausada y el corredor no podrá procesarla.',
      CONFIRM_BUTTON: 'Si, editar'
    },
    CANCEL_CONFIRM: {
      TITLE: 'Retirar Orden',
      TITLE_ORDER: '¿Estás seguro de retirar esta Orden?',
      TITLE_PREORDER: '¿Estás seguro de retirar esta Pre-orden?',
      BODY_ORDER: 'De existir, se cerrarán todas las contraórdenes abiertas asociadas a esta Orden.',
      BODY_PREORDER: ''
    },
    REJECT_CONFIRM: {
      TITLE: 'Rechazar Orden',
      TITLE_ORDER: '¿Estás seguro de rechazar esta Orden?',
      TITLE_PREORDER: '¿Estás seguro de rechazar esta Pre-orden?'
    },
    STATUS: {
      REVIEW_PENDING_BROKER_ORDER: '<b>Pendiente de revisión.</b> El corredor aún no procesó esta Orden.',
      REVIEW_PENDING_BROKER_PREORDER: '<b>Pendiente de revisión.</b> El corredor aún no procesó esta Pre-orden.',
      REVIEW_PENDING_ORDER: '<b>Pendiente de revisión.</b> Aún no procesaste esta Orden.',
      REVIEW_PENDING_PREORDER: '<b>Pendiente de revisión.</b> Aún no procesaste esta Pre-orden.',
      REJECTED_ORDER: '<b>Rechazada.</b> El corredor decidió no publicar esta Orden.',
      REJECTED_PREORDER: '<b>Rechazada.</b> El corredor decidió no publicar esta Pre-orden.',
      CANCELED_ORDER: '<b>Cancelada.</b> Esta Orden fue retirada.',
      CANCELED_PREORDER: '<b>Cancelada.</b> Esta Pre-orden fue retirada.',
      PUBLISHED_ORDER: '<b>Publicada.</b> El corredor ha decidido publicar esta Orden.',
      PUBLISHED_PREORDER: '<b>Publicada.</b> El corredor ha publicado esta Pre-orden.',
      EXPIRED_ORDER: '<b>Cancelada.</b> Esta Orden fue dada de baja automáticamente por el sistema al finalizar el día.',
      EXPIRED_PREORDER: '<b>Cancelada.</b> Esta Pre-orden fue dada de baja automáticamente por el sistema al finalizar el día.',
    },
    PREBOOK_MODAL: {
      TITLE: 'Ofrece para la Orden ',
      BODY: 'Ingresa la cantidad de toneladas a ofrecer.',
      CONFIRM_BUTTON: 'Pre-anotar'
    },
    UNAVAILABLE_MODAL: {
      TITLE: 'Orden no disponible',
      BODY: 'Esta Orden ya no se encuentra disponible para operar.'
    },
    EDIT: 'Editar Orden...',
    REPUBLISH: 'Republicar Orden...',
    BOOK: 'Anotar',
    PREBOOK: 'Pre-anotar',
    REVIEW: 'Revisar...',
    NEGOTIATION_REQUEST: 'Pedido de negociación'
  },
  COUNTERORDER_VIEW: {
    BOOK: 'Anotar',
    BOOKING_DATE_FORMAT: 'EEEE, d \'de\' MMMM \'de\' yyyy \'a las\' HH:mm',
    NEW: 'Nueva Contraorden',
    LINKED: 'Negocios vinculados',
    AMENDMENT: {
      ALERT: '<b>¡Importante!</b> Se están revisando las condiciones del acuerdo realizado el <b>{{date}}</b>. Las condiciones originales permanecerán vigentes hasta tanto se acepten las enmiendas, momento a partir del cual se generarán nuevas versiones de la documentación relacionada.',
      SEND: 'Proponer enmiendas',
      ACCEPT: {
        TITLE: 'Aceptar las enmiendas',
        BODY: 'Se están por aceptar todos los cambios sugeridos. Se va a generar un nuevo documento reflejando las nuevas condiciones. ¿Estás seguro de aceptar todas las enmiendas?',
        BUTTON: 'Aceptar las enmiendas'
      },
      REJECT: {
        TITLE: 'Rechazar todas las enmiendas',
        BODY: 'Se están por ignorar todos los cambios sugeridos, se volverá a las condiciones negociadas originalmente. ¿Estás seguro de rechazar todas las enmiendas?'
      }
    },
    CANCELLATION: {
      ALERT: '<b>¡Importante!</b> Se está evaluando anular el acuerdo realizado el <b>{{date}}</b>. Las condiciones originales permanecerán vigentes hasta tanto se confirme la anulación.',
      ACCEPT: {
        TITLE: 'Anular Negocio',
        BODY: 'Estás por aceptar la propuesta de anulación de este Negocio. ¿Estás seguro?<br><br>Esta acción no puede deshacerse.',
        BUTTON: 'Aceptar y anular'
      }
    },
    NEGOTIATION_REQUEST_CONFIRM: {
      TITLE: '¿Estás seguro de aceptar el pedido de negociación?',
      BODY: 'La Contraorden quedará en firme y la contraparte podrá anotar.'
    },
    NEGOTIATION_REQUEST_REJECT: {
      TITLE: '¿Estás seguro de rechazar el pedido de negociación?'
    },
    STATUS: {
      PAUSED: '<b>Orden en pausa.</b> No es posible realizar operaciones sobre esta Contraorden momentáneamente.',
      SENT: '<b>Contraorden enviada.</b> Esperando devolución de la contraparte.',
      RECEIVED: '<b>Contraorden recibida.</b> Debes responder.',
      CANCELED: '<b>Contraorden cancelada.</b>',
      REJECTED: '<b>Contraorden rechazada.</b>',
      BOOKED: '<b>Anotada.</b>',
      AMENDMENT: {
        IN_PROGRESS: '<b>Enmiendas en negociación.</b> Esperando a las contrapartes.',
        SENT: '<b>Haz sugerido enmiendas al acuerdo original</b>. Esperando devolución de la contraparte.',
        RECEIVED: 'La contraparte propone <b>enmiendas al acuerdo original</b>. Debes responder.'
      },
      CANCELLATION: {
        IN_PROGRESS: '<b>Propuesta de anulación pendiente.</b> Esperando a las contrapartes.',
        SENT: '<b>Propusiste la anulación del Negocio</b>. Esperando devolución de la contraparte.',
        RECEIVED: 'La contraparte propone <b>anular este Negocio</b>. Debes responder.'
      },
      PREBOOK: {
        RECEIVED: '<b>Pre-anote recibido.</b> Debes responder.',
        REQUESTED: '<b>Pre-anote solicitado.</b> Esperando devolución de la contraparte.',
        REJECTED: '<b>Pre-anote rechazado.</b>'
      },
      DISCLOSURE: {
        SENT: '<b>Solicitud de divulgación enviada.</b> Esperando devolución de la contraparte.',
        RECEIVED: '<b>Solicitud de divulgación recibida.</b> Debes responder.',
        ANSWERED: '<b>Solicitud de divulgación respondida.</b> Esperando devolución de la contraparte.'
      },
      NEGOTIATION_REQUEST: {
        SENT: '<b>Pedido de negociación enviado.</b> Esperando devolución de la contraparte.',
        RECEIVED: '<b>Pedido de negociación recibido.</b> Debes responder.',
        ACCEPTED: '<b>Pedido de negociación aceptado.</b>',
        REJECTED: '<b>Pedido de negociación rechazado.</b>'
      }
    },
    UNAVAILABLE_MODAL: {
      TITLE: 'Contrarden no disponible',
      BODY: 'Esta Contraorden ya no se encuentra disponible para operar.'
    }
  },
  MAX_QUANTITY: {
    DESCRIPTION: 'El anote de esta negociación implicaría exceder la cantidad de compra máxima definida.',
    MAX_QUANTITY: 'Cantidad máxima',
    BOOKED: 'Ya anotado',
    THIS_NEGOTIATION: 'En esta negociacion',
    TOTAL: 'Total',
    CONTINUE: 'Si, continuar',
    TO_BE_OFFERED: 'A Ofrecer'
  },
  MY_OPERATIONS_VIEW: {
    NO_RECORDS: 'No se registran movimientos para esta Empresa.',
    SUMMARIZE: 'Un total de <span class="quantity">{{quantityOrder}}</span> Orden(es)',
    TOOLTIPS: {
      REPUBLISH: 'Republicar Orden',
      REPUBLISH_AUCTION: 'Republicar Subasta'
    },
    BULK_REPUBLISH: {
      TITLE: 'Republicar Masivo',
      MODAL_TITLE: '¿Quieres republicar Órdenes?',
      ACCEPT_BUTTON: 'Republicar las ódenes seleccionadas',
      MODAL_DESC_PRE: 'El día',
      MODAL_DESC_POST: 'la plataforma dió automáticamente de baja las siguientes Órdenes. Selecciona aquellas que quieras republicar.',
      TOOLTIP_DATE: 'Esta fecha fue trasladada automáticamente al día de hoy, respetando la duración del período.',
      EDIT_PRICES: 'Editar precios',
      PUBLISHING: 'Publicando...'
    }
  },
  NEGOTIATION_LIST: {
    UNDER_NEGOTIATION: 'En negociación',
    COUNTERORDERS: 'Contraorden(es)',
    BOOKED: 'Anotadas',
    OF: 'de',
    AMENDMENT_SENT: 'Enmienda enviada',
    AMENDMENT_RECEIVED: 'Enmienda recibida',
    COUNTERORDER_SENT: 'Contraorden enviada',
    COUNTERORDER_RECEIVED: 'Contraorden recibida',
    PREBOOK_REQUESTED: 'Pre-anote solicitado',
    PREBOOK_RECEIVED: 'Pre-anote recibido'
  },
  TRADED_VIEW: {
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> operación(es)',
    SUMARIZE_TONS: ' por <span class="quantity">{{quantity}}</span> {{unit}}.',
    SUMARIZE_PRIVATE_TONS: 'Operaciones privadas por <span class="quantity">{{quantity}}</span> {{unit}}.',
    PLACEHOLDER: 'La plataforma aún no registra movimientos.'
  },
  NEGOTIATION_ACTIONS: {
    AMENDMENTS: 'Proponer enmiendas',
    ASSIGN_SLOTS: 'Asignar Cupos',
    CHAT: 'Enviar un mensaje',
    DELEGATE_TRADE_HELP: '<b>Atención!</b> La gestión ya no quedará a cargo de tu Empresa.',
    DELEGATE_TRADE: 'Delegar Negocio a un Corredor',
    DELEGATE: 'Delegar Negocio',
    DOWNLOAD_BUSINESS_CONFIRMATION: 'Descargar confirmación del negocio',
    DOWNLOAD_CONTRACT: 'Descargar Contrato',
    DOWNLOAD_TRACK_RECORD: 'Descargar historial del negocio',
    EDIT_BOOKING_DATE_HELP: '<b>Atención!</b> La modificación de la fecha de un negocio quedará reflejada en el historial y todas las partes involucradas serán notificadas.',
    EDIT_BOOKING_DATE: 'Editar fecha del negocio',
    EXTERNAL_ID: 'Administrar referencia externa',
    LINK_NEGOTIATIONS: 'Vincular Negocios',
    REQUEST_CANCELLATION_BODY: 'Tu contraparte va a recibir una solicitud de anulación que deberá aceptar para hacer efectiva la anulación de este Negocio.',
    REQUEST_CANCELLATION: 'Solicitar anulación',
    TURN_BARTER: 'Convertir en un Canje'
  },
  COMPANY_TRADED_VIEW: {
    BOUGHT: 'Comprado',
    LIQUIDATED: 'Liquidado',
    BONUS: 'Bonus',
    NO_RECORDS: 'La plataforma aún no registra movimientos.',
    SOLD: 'Vendido',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> operación(es)',
    SUMARIZE_VOLUME: ' por <span class="quantity">{{quantity}}</span> {{unit}}.',
    UNLOADED: 'Aplicado',
    VIEWMODE: {
      GROUPED: 'Agrupado por Productos',
      UNGROUPED: 'Desagrupado'
    }
  },
  PREORDERS_VIEW: {
    NO_RECORDS: 'Su Empresa no posee Pre-órdenes.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Pre-orden(es)',
    SELECTED: '<span class="quantity">{{quantity}}</span> Pre-orden(es) seleccionada(s)',
    DESCRIPTION_0: 'Estas son las Órdenes asignadas por tus mandantes. Puedes revisarlas, editarlas y agruparlas con otras Órdenes similares de otros clientes para concentrar la oferta en una sola Orden de mayor volumen, o bien rechazarla. Los clientes mandantes podrán conocer si la Orden asignada fue revisada y publicada o bien rechazada por el corredor.',
    DESCRIPTION_1: 'Si asignaste un corredor para que opere tu Orden, en esta pantalla podrás conocer si la Orden fue revisada y publicada o bien rechazada por el corredor.',
    TABLE_HEADER: {
      BROKER: 'Corredor asignado'
    },
    REVIEW: 'Revisar...',
    CANCEL_CONFIRM: {
      MODAL_TITLE: 'Retirar Pre-órdenes',
      TITLE_0: '¿Estás seguro de retirar estas Pre-órdenes?',
      TITLE_1: '¿Estás seguro de retirar esta Pre-orden?',
      BODY_0: 'Pre-órdenes: {{ id }}',
      BODY_1: 'Pre-orden: {{ id }}'
    },
    REJECT_CONFIRM: {
      MODAL_TITLE: 'Rechazar Órdenes',
      TITLE_0: '¿Estás seguro de rechazar estas Pre-órdenes?',
      TITLE_1: '¿Estás seguro de rechazar esta Pre-orden?',
      BODY_0: 'Pre-órdenes: {{ id }}',
      BODY_1: 'Pre-orden: {{ id }}'
    }
  },
  TEAM_VIEW: {
    ACTIVE: 'Activo',
    ADD_USER: 'Agregar Usuario',
    EMAIL: 'Email',
    HEADING: 'Crea Usuarios para tu Empresa y asígnales distintos permisos.',
    IDENTITY_NUMER: 'Documento de Identidad',
    INVITATION_SENT: 'Invitación enviada',
    NAME_LASTNAME: 'Nombre y Apellido',
    NAME: 'Equipo de {{company}}',
    RESTRICT_VISIBILITY: 'Restringir visibilidad a sus zonas',
    ROLE: 'Perfil',
    USERS: 'Usuarios',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Quitar Usuario',
      TITLE: '¿Estás seguro de quitar este Usuario del equipo?',
      BODY: 'Si lo deseas, siempre puedes volver a incluir este mismo Usuario más adelante.'
    },
    INVITE_MODAL: {
      ADD_USER_BUTTON: 'Agregar Usuario',
      ALREADY_EXISTS: 'El Usuario ya forma parte de la Empresa.',
      BODY: 'Ingresa un <b>email</b> válido y define el <b>perfil</b>. Le enviaremos un correo al Usuario para guiarlo en el proceso de alta.',
      EMAIL: 'Email',
      ROLE: 'Perfil',
      SELECT_ROLE: 'Selecciona un perfil',
      TITLE: 'Agregar Usuario a {{company}}'
    }
  },
  MANAGERS: {
    MANAGERS: 'Representantes',
    MANAGER: 'Representante',
    APPLY_TO_BE_MANAGER: 'Solicitar ser representante',
    REPRESENTS: 'Representa a',
    NO_MANAGERS: 'Tu empresa no tiene representantes cargados',
    REQUEST: {
      REQUEST: 'Solicitud',
      SEND: 'Enviar solicitud'
    },
    STATUS: {
      ACTIVE: 'Activo',
      PENDING: 'Pendiente',
      ACCEPTED: 'Aceptado',
      ACCEPT: 'Aceptar solicitud'
    },
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Desvincular representante',
      TITLE: '¿Estás seguro de desvincular este representante de tu empresa?',
      BODY: 'Este usuario perderá todo los permisos y el acceso a tu empresa'
    }
  },
  COUNTERPARTIES: {
    APPROVED: 'Aprobada',
    HEADING: 'Aprueba en esta sección las contrapartes con las que tu Empresa podrá operar.',
    MY_NETWORK: 'Mi Red',
    NEW: 'Nueva!',
    NO_COUNTERPARTS: 'No se registran contrapartes.',
    VERIFICATION_DATE: 'Fecha de verificación',
    STATUS: {
      REMOVE_TITLE: '¿Estás seguro de bloquear a esta Empresa?',
      REMOVE_BODY: 'Esta Empresa pertenece actualmente a tu Red. Al bloquearla, también estarás eliminando este vínculo.',
      REMOVE_TOOLTIP: 'Bloquear Empresa',
      YES_REMOVE: 'Si, bloquear'
    },
    NETWORK: {
      ACCEPT: 'Aceptar solicitud',
      ADD: 'Agregar a mi Red',
      CANCEL: 'Cancelar solicitud',
      PENDING: 'Pendiente',
      REMOVE_BODY: 'Puedes volver a agregarla más tarde, pero puede requerir una aceptación de la otra Empresa.',
      REMOVE_TITLE: '¿Estás seguro de quitar esta Empresa de tu Red?',
      REMOVE_TOOLTIP: 'Quitar de mi Red',
      WITHDRAW_BODY: 'Puedes volver a enviar una nueva solicitud más tarde.',
      WITHDRAW_TITLE: '¿Quieres retirar esta solicitud?',
      MODAL: {
        ADD_MORE: 'Agregar {{quantity}} filas...',
        BULK_LOAD: 'Carga múltiple',
        DESCRIPTION: 'Las Empresas que agregues y no pertezcan al Mercado abierto, sólo estarán visibles para tu Empresa.',
        EMAIL: 'Email del administrador',
        EMAILS_ERROR: '<p><b>Uno de los emails ingresados es el tuyo.</b></p>Recuerda que según nuestros <a href="/links/terms">Términos y Condiciones</a>, sólo apoderados de la Empresa pueden registrarse como Administradores. Si efectivamente estás autorizado, completa el <a href="/select-market">formulario de registro</a>.',
        EMAILS_HELP: 'Ingresa el email de los Usuarios de la Empresa que estás agregando. Les enviaremos un correo para que se registren.',
        EMAILS: 'Email de los administradores',
        FISCAL_ID_HELP: 'Busca la Empresa por su identificador fiscal.',
        MODE_NOT_AVAILABLE: 'Este modo no está disponible en pantallas pequeñas.',
        SINGLE_LOAD: 'Carga individual',
        TITLE: 'Invitar una Empresa'
      }
    }
  },
  COMPANY_LOCATIONS_VIEW: {
    TITLE: 'Lugares de entrega para {{company}}',
    ADD_LOCATION_BUTTON: 'Agregar Lugar',
    HEADING: 'Administra en esta sección los lugares de entrega para tu Empresa.',
    INFO: '<b>Importante!</b> Recuerda completar todos los lugares donde tu Empresa compra. No podrás ver Vendedores ni comprar en lugares que no hayas especificado.',
    LOCATION_NOT_DEFINED: 'Aún no has definido ningún lugar de entrega.',
    ZONE: 'Zona',
    LOCATION: 'Lugar',
    BUYER_OPTION: 'Opción comprador',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Eliminar Lugar',
      TITLE: '¿Estás seguro de eliminar este Lugar?'
    },
    ADD_LOCATION_TITLE: 'Agregar lugar de entrega para {{company}}'
  },
  CERTIFICATES: {
    TITLE: 'Certificados de tu Empresa',
    ADD_CERTIFICATE: 'Agregar Certificado',
    HEADING: 'Administra los Certificados de gestión y producto de tu Empresa. Los documentos vigentes serán visibles para tus contrapartes y podrás vincularlos con tus Órdenes con propósitos de trazabilidad.',
    NO_DATA: 'Aún no se ha agregado ningún Certificado.',
    TABLE: {
      PRODUCT_CATEGORY: 'Categoría',
      NUMBER: 'Nro.',
      DATES: 'Vigencia',
      SCOPE: 'Alcance',
      VERIFIED: 'Verificado'
    },
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Eliminar Certificado',
      TITLE: '¿Estás seguro de eliminar este Certificado?'
    },
    MODAL: {
      TITLE: 'Agregar Certificado',
      BODY: 'Los Certificados y documentos que envíes serán publicados en la plataforma. Estos documentos podrían enviarse a una autoridad externa para su verificación.',
      HOLDER: 'Titular del certificado',
      HOLDER_PLACEHOLDER: 'Titular...',
      HOLDER_HELP: 'Debe ser consistente con el titular/nombre del solicitante impreso en el Certificado.',
      TYPE_PLACEHOLDER: 'Selecciona uno...',
      PRODUCT_CATEGORY: 'Categoría de producto',
      PRODUCT_CATEGORY_PLACEHOLDER: 'Ingrese la categoría...',
      NUMBER: 'Nro.',
      NUMBER_PLACEHOLDER: 'Ingrese número/referencia...',
      NAME_PLACEHOLDER: 'Selecciona uno...',
      OTHER_NAME_PLACEHOLDER: 'Ingresa el nombre...',
      ISSUED_BY_PLACEHOLDER: 'Selecciona uno...',
      OTHER_ISSUED_BY_PLACEHOLDER: 'Ingresa el nombre...',
      OPTION_OTHER: 'Otro...',
      DATE_FROM: 'Vigente desde',
      DATE_TO: 'Fecha de caducidad',
      SCOPE: 'Alcance',
      SCOPE_PLACEHOLDER: 'Alcance del Certificado (preferentemente en idioma inglés)...',
      SCOPE_HELP: 'Ingrese los productos y la información de auditoría relevante que cubre el Certificado (y la que excluye), Ej. el certificado verifica el empacado de alimentos frescos para transporte y conservación.',
      IMAGE: 'Imagen del Certificado',
      IMAGE_HELP: 'Suba una captura legible del Certificado. Formatos admitidos: JPG, PNG, GIF o SVG.'
    },
    BUTTON: {
      TITLE: 'Ver Certificados de la Empresa',
      MODAL: 'Certificados vigentes de {{company}}'
    }
  },
  LOCATION_PICKER: {
    ADD_LOCATION_BUTTON: 'Agregar Lugar',
    TITLE: 'Agregar lugar',
    BODY_0: 'Puedes definir tanto una <b>Zona</b>, como una <b>Localidad</b>.',
    BODY_1: 'Recuerda que puedes agregar tantos lugares como desees.',
    SELECT_ZONE: 'Selecciona una Zona',
    INCLUDE_LOCATIONS: 'Incluye las localidades:',
    PLACEHOLDER: 'Ingresa una localidad...',
    CONFIRM_BUTTON: 'Agregar Lugar'
  },
  TEMPLATE_NAME_PICKER: {
    SAVE_AS_TEMPLATE_BUTTON: 'Guardar como Plantilla...',
    SAVE_AS_TEMPLATE_BUTTON_ABBR: 'Plantilla...',
    TITLE: 'Ingresa un título para esta Plantilla',
    CONFIRM_BUTTON: 'Guardar Plantilla',
    DESCRIPTION: 'Las Plantillas permiten crear Órdenes rápidamente y pueden reutilizarse tantas veces como quieras. Dale un nombre que te ayude a identificarla fácilmente más tarde.'
  },
  REFERENCE_NUMBER_PICKER: {
    TITLE: 'Administrar referencia externa',
    DESCRIPTION: 'Puedes asociarle a este negocio una referencia o identificador externo, por ejemplo uno generado por el sistema de gestión interno de la Empresa. Esto generará una nueva versión de la documentación asociada (historial de negocio, Contrato, confirmación de negocio, etc.) reflejando este dato.',
    PLACEHOLDER: 'Referencia externa...'
  },
  TRACK_RECORD: {
    ADD_COMMENT: 'Agregar un comentario',
    COMMENT: 'Comentar...',
    COMMENT_PLACEHOLDER: 'Ingresa un comentario...',
    FIELD: 'Campo',
    NEW_VALUE: 'Nuevo valor',
    ORIGINAL_VALUE: 'Valor original',
    PRIVATE: 'Sólo visible para mi Empresa',
    PRIVATE_HELP: 'Los comentarios privados no serán incluídos en la documentación generada.',
    TIE_BREAKER: 'Omitido. Hubo una oferta anterior definida automáticamente.',
    TITLE: 'Historial',
    USER_AND_ACTION: 'Usuario y acción',
    FIELDS: {
      PRICE_UNIT: 'Moneda',
      PRICE_YEAR: 'Año Premium',
      PRICE_MONTH: 'Mes Premium',
      ARBITRATION_CHAMBER: 'Cámara arbitral',
      DATE_FROM: 'Fecha desde',
      DATE_TO: 'Fecha hasta',
      CLAUSES: 'Cláusula',
      REFERENCE: 'Referencia externa'
    },
    EVENTS: {
      ATTACHMENT: {
        CREATED: 'adjuntó el archivo',
        DELETED: 'eliminó el archivo',
        DOWNLOADED: 'descargó el archivo'
      },
      AUCTION: {
        BIDDED: 'realizó una oferta con los siguientes datos:',
        CREATED: 'creó la Subasta.',
        FINISHED: 'Subasta finalizada.'
      },
      BARTER: {
        ACCEPTED: 'aceptó los términos del Canje.'
      },
      CERTIFICATE_OF_DEPOSIT: {
        CREATED: 'creó el Certificado de depósito.'
      },
      CHAT: {
        FILE: {
          SENT: ''
        },
        MESSAGE: {
          SENT: ''
        }
      },
      COMMENT: {
        CREATED: 'comentó:'
      },
      COMPANY: {
        DISABLED: 'Compañia deshabilitada.',
      },
      CREDIT: {
        CREATED: 'inició el proceso de Solicitud.',
        GRANTED: 'indicó la Solicitud como otorgada.',
        REJECTED: 'rechazó la Solicitud.',
        RESTORED: 'restauró la Solicitud a una versión anterior.',
        UPDATED: 'realizó cambios en la Solicitud.',
        WITHDRAWN: 'retiró la Solicitud.'
      },
      DOA: {
        APPROVED: 'La delegación de autoridad fue aprobada.',
        CREATED: 'creó una delegación de autoridad.',
        REJECTED: 'La delegación de autoridad fue rechazada.',
        WITHDRAWN: 'La delegación de autoridad fue retirada.',
        VOTE: {
          APPROVED: 'emitió su votó: Aprobado.',
          REJECTED: 'emitió su votó: Rechazado.'
        }
      },
      ENVELOPE: {
        CANCELED: 'canceló el Sobre.',
        COMPLETED: 'Todos los firmantes firmaron.',
        CREATED: 'creó el Sobre.',
        SIGNERS: {
          DEFINED: 'definió los siguientes firmantes:',
          NOTIFIED: 'Los firmantes fueron notificados.',
          SIGNED: 'Uno de los firmantes firmó'
        }
      },
      NEGOTIATION: {
        BOOKED: 'anotó.',
        ORIGINAL_BOOKING_DATE_UPDATED: 'modificó la fecha del Negocio.',
        PRE_BOOKED: 'solicitó un pre-anote.',
        REJECTED: 'rechazó.',
        UPDATED: 'envió una Contraorden con estos cambios:',
        WITHDRAWN: 'canceló la Contraorden.',
        AMENDMENT: {
          ACCEPTED: 'aceptó las enmiendas.',
          REJECTED: 'rechazó las enmiendas.',
          UPDATED: 'propuso las siguientes enmiendas:'
        },
        BROKER: {
          UPDATED: 'delegó la gestión del Negocio a otra Empresa.'
        },
        CANCEL: {
          ACCEPTED: 'aceptó anular el Negocio.',
          REJECTED: 'rechazó anular el Negocio.',
          REQUESTED: 'solicitó anular el Negocio.',
          WITHDRAWN: 'retiró la solicitud de anulación.'
        },
        EXTERNAL_REFERENCE: {
          UPDATED: 'ha realizado una modificación en los siguientes campos:'
        },
        REPRESENTED: {
          UPDATED: 'modificó las contrapartes:'
        },
        REQUEST: {
          ACCEPTED: 'aceptó el pedido de negociación.',
          REJECTED: 'rechazó el pedido de negociación.',
          SENT: 'envió un pedido de negociación.'
        }
      },
      ORDER: {
        CREATED: 'creó la Orden.',
        BROKER: {
          UPDATED: 'delegó la gestión del Negocio a otra Empresa.'
        }
      },
      FIXATION_REQUEST: {
        UPDATED: 'Actualizó el preció y/o el volumen de la solicitud.',
        CREATED: 'Creó una nueva solicitud de fijación.'
      },
      APPLICATION: {
        APPROVED_AMOUNT_UPDATED: 'modificó el monto aprobado.',
        APPROVED: 'aprobó un paso de la Solicitud.',
        CREATED: 'inició el proceso de Solicitud.',
        FORM: 'actualizó datos de la Solicitud.',
        GRANTED: 'otorgó la Solicitud.',
        PRODUCT_UPDATED: 'modificó propiedades del Producto de la Solicitud.',
        REJECTED: 'rechazó la Solicitud.',
        REVISION: 'creó una revisión de la Solicitud.',
        UNDO: 'regresó a un paso previo de la Solicitud.',
        UPDATED: 'realizó cambios en la Solicitud.',
        WITHDRAWN: 'retiró la Solicitud.'
      }
    }
  },
  MARKET_SUMMARY: {
    ZONES: 'Zonas',
    LOCATIONS: 'Localidades',
    DELIVERY_LOCATION: 'Lugar de entrega',
    PURCHASE: 'Compra',
    SALE: 'Venta',
    LAST_NEGOTIATED: 'Último Negociado',
    LAST_NEGOTIATED_ABBR: 'Últ. Neg.',
    OFFERED_VOLUME: 'Volumen ofrecido (Ventas)',
    OFFERED_VOLUME_ABBR: 'Vol.',
    DEFERRED: 'Diferido'
  },
  WORKING_ORDERS: {
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Orden(es)',
    SELECTED: '<span class="quantity">{{quantity}}</span> Orden(es) seleccionada(s)',
    BOOK: 'Anotar',
    SELECT_ALL: 'Seleccionar todo',
    UNAUTHORIZED: 'No autorizada',
    CONSIGNMENT_CONDITIONS: 'Condiciones de consignación',
    ARBITRATION_CHAMBER: 'Cámara arbitral',
    CONTRACT_DEVIATIONS: 'Existen desviaciones del contrato estándar',
    AUCTION_DATE: 'Fecha de la Subasta',
    AUCTION_DURATION: 'Duración de la Subasta',
    AUCTION_DATE_FORMAT: 'dd/MM/yy - HH:mm',
    MINUTES: 'minutos',
    BUYER_OPTION: 'Opción comprador',
    CHAT: 'Chat',
    VIEWMODE: {
      TABLE: 'Tabla',
      EXPANDED: 'Expandida',
      GALLERY: 'Galería',
      MAP: 'Mapa'
    }
  },
  TEMPLATES_VIEW: {
    TITLE: 'Plantillas de Órdenes',
    NO_RECORDS: 'Esta Empresa aún no tiene Plantillas.',
    DESCRIPTION: 'Las Plantillas permiten crear Órdenes rápidamente y pueden reutilizarse tantas veces como quieras.',
    TABLE_HEADER: {
      DATE: 'Creada'
    },
    VIEW_TEMPLATE: 'Ver Plantilla',
    CONSIGNMENT_CONDITIONS: 'Condiciones de consignación',
    ARBITRATION_CHAMBER: 'Cámara arbitral',
    CONTRACT_DEVIATIONS: 'Existen desviaciones del contrato estándar',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Eliminar Plantilla',
      TITLE: '¿Estás seguro de eliminar esta Plantilla?'
    },
    DELETE: 'Eliminar',
    AUCTION_DATE: 'Fecha de la Subasta',
    AUCTION_DURATION: 'Duración de la Subasta',
    AUCTION_DATE_FORMAT: 'dd/MM/yy (HH:mm)',
    MINUTES: 'minutos'
  },
  FILTERS: {
    ACTIVE: 'Activos',
    CASH: 'Contado contra entrega',
    CLEAR: 'Quitar filtro',
    CLOSED_BARTER: 'Cerrado',
    CLOSED: 'Cerrada',
    COMMERCIAL_ZONE: 'Zona comercial',
    DELIVERY_RANGE: 'Rango de entrega',
    FIXATION_PERIOD: 'Período de fijación',
    DELIVERY_ZONE: 'Zona de entrega',
    ASSIGNMENT_RANGE: 'Rango de asignación',
    ENVELOPE_STATUS: 'Estado',
    EXPIRED: 'Vencidos',
    FILTER_CONDITIONS: 'Condiciones de filtro',
    FILTER: 'Filtrar',
    HIDE: 'Ocultar filtros',
    INACTIVE: 'Inactivos',
    LABELS: 'Etiquetas',
    ON_DATE: 'En fecha',
    OPEN_BARTER: 'Abierto',
    OPEN: 'Abierta',
    OPERATION_TYPE: 'Tipo de operación',
    ORDER_TYPE: 'Tipo de publicación',
    PRICE: 'Precio',
    REMOVE_ALL: 'Quitar todos',
    SEE_ALL: 'Ver todo...',
    SELECT_PERIOD: 'Selecciona un período',
    SELECT_RANGE: 'Seleccionar rango',
    SHOW: 'Mostrar filtros',
    TEXT_PLACEHOLDER: 'Texto...',
    VALIDITY: 'Vigencia',
    WITH_CONTRACT: 'Con Contrato',
    WITHOUT_CONTRACT: 'Sin Contrato',
    SEARCH_CONTRACT: 'Buscar contrato',
    ARCHIVED: 'Archivados',
    VALID: 'En vigencia',
    FINTECH: {
      ACTIVE: 'Activas',
      GRANTED: 'Otorgadas',
      REJECTED: 'Rechazadas',
      WITHDRAWN: 'Retiradas',
      EXPIRED: 'Caducadas'
    },
    CONDITIONS: {
      AFTER: 'Después de',
      BEFORE: 'Antes de',
      CONTAINS: 'Contiene',
      ENDS: 'Termina con',
      EQUAL: 'Es igual a',
      FALSE: 'Falso',
      GREATER_THAN: 'Mayor a',
      IS: 'Es exactamente',
      LAST_MONTH: 'Último mes',
      LAST_WEEK: 'Última semana',
      LOWER_THAN: 'Menor a',
      STARTS: 'Comienza con',
      NONE: 'Ninguna',
      NOT: 'No contiene',
      TRUE: 'Verdadero',
      WHEN: 'Fecha exacta',
      RANGE: 'Rango'
    }
  },
  DOCUMENTS: {
    ENTER: 'Ingresar',
    RELATED_DOCS: 'Documentos relacionados',
    SELECT_PRODUCT: 'Selecciona un producto',
    SELECTED_MARKET: 'Mercado:'
  },
  INBOX: {
    DATE_FORMAT: 'dd/MM/yy, HH:mm',
    EMPTY_UNREAD: 'No tienes notificaciones sin leer.',
    EMPTY: 'Aún no recibiste ningún mensaje.',
    MARK_ALL_AS_READ: 'Marcar todas como leídas',
    MARK_AS_READ: 'Marcar como leída',
    MARK_AS_UNREAD: 'Marcar como no leída',
    NOTIFICATIONS: 'Notificaciones',
    SEE_ALL: 'Ver todas...',
    TO: 'para <b>{{company}}</b>'
  },
  SUPER_ADMIN: {
    ACTIVATE_COMPANY_MODAL: {
      TITLE: 'Activación de Empresa {{company}}',
      BODY: 'Selecciona el <b>nivel</b> de activación.',
      LEVEL: 'Nivel',
      ACTIVATE_BUTTON: 'Modificar activación'
    }
  },
  USER_PROFILE: {
    LANGUAGE: 'Idioma',
    NOTIFICATIONS: 'Notificaciones',
    PHONE: 'Teléfono celular',
    PROFILE_HELP: 'Mantén actualizada tu información personal.',
    PROFILE_TITLE: 'Datos personales',
    PROFILE_UPDATE: 'Actualizar datos',
    PROFILE_UPDATED: '<b>Perfil actualizado correctamente!</b>',
    SECURITY_TITLE: 'Seguridad',
    CHANNELS: {
      ENABLE: 'Especifica a través de que canales queres recibir notificaciones.',
      HELP: 'Controles para asegurarte de que recibes las notificaciones que te interesan.',
      TITLE: 'Canales'
    },
    PASSWORD: {
      ERROR: '<b>¡Contraseña incorrecta!</b>',
      HELP: 'Elige una contraseña única para proteger tu cuenta. Recomendamos cambiar tu contraseña de acceso periódicamente.',
      NEW: 'Nueva Contraseña',
      OLD: 'Contraseña actual',
      UPDATE: 'Cambiar contraseña',
      UPDATED: '<b>¡Contraseña actualizada correctamente!</b>'
    }
  },
  CELLPHONE_VERIFICATION: {
    MODIFY: 'Modificar número',
    ADD: 'Agregar número',
    MODAL: {
      TITLE: 'Configura tu teléfono celular',
      COUNTRY_CODE: 'Código de país',
      AREA_CODE: 'Código de área',
      SELECT_CODE: 'Selecciona un código de país',
      NUMBER_HELP: 'Cód. de país + Cód. de área + Nº. Ejemplo: +54 (911) 4323-4556',
      VERIFICATION_DESCRIPTION: 'Te enviamos un código de verificación por mensaje de texto. Si luego de unos minutos, no lo recibiste,',
      SEND_AGAIN: 'haz click aquí para volver a enviar',
      NEXT: 'Siguiente',
      ERROR: 'El código de verificación ingresado es incorrecto.',
      CODE_FROM_TEXT_MESSAGE: 'Código de verificación',
      VALIDATING: 'Validando código...',
      ALLOW_WHATSAPP: 'Acepto recibir notificaciones por <b>WhatsApp</b>',
      ALLOW_WHATSAPP_HELP: 'De estar disponible el servicio para el número indicado, la plataforma notificará las novedades mediante la aplicación <b>WhatsApp</b>, esto incluye la posibilidad de realizar algunas acciones sin necesidad de entrar a la plataforma. De lo contrario, se realizarán por SMS.'
    }
  },
  PAGINATOR: {
    FIRST_PAGE: 'Primera página',
    LAST_PAGE: 'Última página',
    NEXT_PAGE: 'Página siguiente',
    OF: 'de',
    PREV_PAGE: 'Página anterior',
    SUMMARY: 'Página {{page}} de {{total}}'
  },
  COUNTDOWN: {
    DAY: 'Día(s)',
    HOUR: 'Hora(s)',
    MINUTE: 'Minuto(s)',
    SECOND: 'Segundo(s)',
    DAY_ABBR: 'd',
    HOUR_ABBR: 'h',
    MINUTE_ABBR: 'm',
    SECOND_ABBR: 's',
    EXPIRED: 'Finalizado'
  },
  PRICE_FORM: {
    FIXED: 'Fijo',
    PREMIUM: 'Basis',
    TO_BE_FIXED: {
      LABEL: 'A fijar',
      CONDITIONS: 'Condiciones de fijación:',
      PLACEHOLDER: 'Ingresa las condiciones de fijación...'
    },
    CONSIGNMENT: {
      LABEL: 'A Consignar',
      CONDITIONS: 'Condiciones de consignación:',
      PLACEHOLDER: 'Ingresa las condiciones de consignación...'
    }
  },
  QUALITY_INPUT: {
    TITLE: 'Editar parámetros',
    ACTION: 'Aplicar cambios'
  },
  ASSIGNEES_MANAGER: {
    ADD_ME_BODY: '¿Quieres recibir notificaciones cada vez que haya novedades? Puedes desuscribirte en todo momento.',
    ADD_ME: 'Suscribirme a las novedades',
    CREATOR: 'Creador',
    RESPONSIBLE: 'Operador(es)',
    WATCHERS: 'Seguidor(es)',
    MODAL: {
      CLOSE: 'Cerrar',
      PLACEHOLDER: 'Ingresa el nombre o email del Usuario...',
      RESPONSIBLE_HELP: 'Usuarios de tu Empresa responsables de esta entidad. Debe haber al menos uno asignado.',
      RESPONSIBLE: 'Operador(es)',
      TITLE: 'Opciones de seguimiento',
      WATCHERS_HELP: 'Usuarios de tu Empresa que serán notificados sobre novedades en esta entidad.',
      WATCHERS: 'Seguidor(es)'
    }
  },
  COMMERCIAL_MANAGER: {
    TITLE: 'Administración de equipo comercial',
    DESCRIPTION: 'Define los productos y límites de compra para cada Usuario comercial de su Empresa. Comprueba su desempeño diario, semanal y mensual.',
    BUY_TRADES: 'Operaciones de compra',
    SELL_TRADES: 'Operaciones de venta',
    NO_TEAM: 'Tu Empresa no posee un equipo comercial.',
    TABLE: {
      NAME_LASTNAME: 'Nombre y Apellido',
      PRODUCTS: 'Productos',
      DAILY_STOP: 'Límite diario',
      TODAY_BOUGHT: 'Compras de hoy',
      WEEKLY_STOP: 'Límite semanal',
      WEEK_BOUGHT: 'Compras de la semana',
      MONTHLY_STOP: 'Límite mensual',
      MONTH_BOUGHT: 'Compras del mes',
      TOTAL_BOUGHT: 'Compras totales',
      EDIT_PRODUCTS: 'Editar productos...',
      IN_TRADES_1: 'en',
      IN_TRADES_2: 'operacion(es).'
    },
    MODAL: {
      TITLE: 'Elige los productos para:',
      DESCRIPTION: 'Puedes especificar más de uno. Deja este campo vacío para asignarle todos los productos.',
      PLACEHOLDER: 'Ingresa el nombre del producto...'
    }
  },
  MESSENGER: {
    LAUNCHER_TITLE: 'Inicia una conversación...',
    UNREAD_BULLET: 'Tienes mensajes sin leer',
    ONLINE: 'En línea',
    OFFLINE: 'Desconectado',
    TYPING: 'Escribiendo...',
    TRACK_RECORD: 'Los mensajes intercambiados estarán disponibles como parte del historial.',
    PLACEHOLDER: 'Escriba un mensaje y presione Enter...',
    DROP_HERE: 'Arrastra tus archivos aquí...',
    ATTACH: 'Adjuntar...',
    UPLOADING: 'Enviando...',
    TODAY: 'Hoy'
  },
  OPERATION_ALERTS: {
    TITLE: 'Alertas de operaciones',
    ADD: 'Agregar alerta',
    DESCRIPTION: 'Define el criterio de las alertas para las cuales deseás recibir notificaciones.',
    NO_ALERTS: 'No hay alertas definidas.',
    DELETE: {
      ACTION: 'Eliminar alerta',
      TITLE: '¿Estás seguro de eliminar esta alerta?'
    },
    TABLE: {
      ACTIVE: 'Activa',
      PRICE_RANGE: 'Rango de precio'
    },
    TYPE: {
      INDISTINCT: 'Indistinto'
    },
    MODAL: {
      TITLE: 'Agregar alerta',
      DESCRIPTION: 'Vas a recibir una notificación cuando una nueva publicación cumpla estos requisitos:',
      OPERATION_TYPE: 'Tipo de operación',
      DELIVERY_PLACEHOLDER: 'Ingresa uno o más lugares...',
      PLACES_HELP: 'Deja este campo vacío si te es indistinto.',
      DATE_HELP: 'Deja este campo vacío si te es indistinto.'
    }
  },
  MESSENGER_CENTER: {
    DATE_FORMAT: 'd/M/yy',
    EMPTY: 'Aún no enviaste ni recibiste mensajes',
    NO_RESULTS: 'No se encontró ningún contacto ni mensaje',
    SEARCH_PLACEHOLDER: 'Buscar...',
    SELECT_ROOM: 'Selecciona una sala para chatear',
    TITLE: 'Centro de mensajes'
  },
  ADD_PHONE: {
    MESSAGE: '<i class="material-symbols-rounded">smartphone</i> Configura tu celular para recibir <b>notificaciones via SMS</b>!'
  },
  PAYMENT_CONDITION_INPUT: {
    TITLE: 'Editar condiciones de pago',
    SELECT_TERMS: 'Selecciona una condición',
    OTHER_TERM: 'Condición alternativa'
  },
  GOOGLE_PLACES: {
    PLACEHOLDER: 'Buscar lugares...'
  },
  ERROR_LIST: {
    BSDATE: 'La fecha no es válida',
    COMPANY_EXISTS: 'Ya existe una Empresa con este identificador fiscal, pidele al Administrador que te <b>sume al equipo</b>',
    COMPANY_NOT_ACTIVE: 'La Empresa no está activa',
    EMAIL_OT_FOUND: 'El email ingresado no está registrado',
    EMAIL: 'Email no válido',
    FILE_MAX_SIZE: 'El archivo no puede tener más de {{max}}',
    FILE_TYPE: 'Sólo son soportados los siguientes tipos de archivo:',
    FUTURE_TIME: 'La hora ingresada no puede ser anterior a la hora actual',
    MATCHES: 'La contraseña y la confirmación no coinciden',
    MAX_FILES: 'Cantidad máxima de archivos permitidos: {{max}}',
    MAX_LENGTH: 'La cantidad máxima de caracteres es {{max}}',
    MAX: 'Valor máximo permitido: {{max}}',
    MIN_LENGTH: 'La cantidad mínima de caracteres es {{min}}',
    MIN: 'Valor mínimo permitido: {{min}}',
    PATTERN: 'No cumple con los requisitos',
    REQUIRED: 'Este campo es obligatorio',
    TIME_RANGE: 'La hora seleccionada debe encontrarse en el siguiente rango:',
    UNIQUE: 'Email ya utilizado',
    VALID_CUIT: 'El identificador fiscal ingresado no es válido',
    VALID_ID: 'El código de identidad ingresado no es válido',
    WAYBILL_EXISTS: 'Este número ya fue usado'
  },
  PRICE_DISPLAY: {
    TO_BE_FIXED: 'A fijar',
    TO_BE_FIXED_ABBR: 'PAF',
    CONSIGNMENT: 'A Consignar',
    CONSIGNMENT_ABBR: 'Consig.',
    CONSIGNMENT_CONDITIONS: 'Condiciones de consignación:'
  },
  SLOTS: {
    ACCEPT_AND_ASSIGN: 'Aceptar y asignar todo a mi Empresa...',
    ACCEPTED: 'Aceptados',
    ACTORS: 'Intervinientes',
    ADD: 'Agregar Cupos',
    ARRIVAL_DATE: 'Fecha de arribo',
    ASSIGN: 'Asignar',
    ASSIGNED_BY_TO: '<b>{{allocator}}</b> asignó los siguientes Cupos a <b>{{company}}</b>.',
    ASSIGNED_BY: '<b>{{allocator}}</b> asignó los siguientes Cupos.',
    ASSIGNED_QUANTITY: '{{quantity}} asignados',
    ASSIGNED_TRADE: 'Este Negocio tiene asignados un total de <b>{{quantity}} Cupo(s)</b>.',
    ASSIGNED: 'Asignado',
    AVAILABLE_BATCHES: 'Lotes de <b>{{product}}</b> con Cupos pendientes de asignar:',
    BATCH_DETAILS: 'Detalles del lote',
    BATCH_ID: 'Lote de Cupos #{{id}}',
    BATCH: 'Lote de Cupos',
    CONDITIONAL: 'Condicional',
    CONFORMING: 'Conforme',
    CREATED: 'Creados',
    DELETE_BODY: 'Estás por eliminar el Cupo <code>{{slot}}</code>. <span class="text-muted">Si ya había sido asignado, se retirará.</span><br><br>Esta acción no puede deshacerse.',
    DOWNLOAD_PDF: 'Descargar PDF',
    EDIT_ACTORS: 'Editar intervinientes...',
    ESTABLISHMENT_HELP: 'En caso de tener planta de acopio.',
    ESTABLISHMENT: 'Establecimiento',
    ESTIMATED: 'Estimado',
    EXPORT_PERIOD: 'Puedes especificar un período de hasta 30 días para incluir en el reporte.',
    FREIGHT_PAYED: 'Flete Pagado',
    FREIGHT_PAYER: 'Pagador del flete',
    FREIGHT_TO_PAY: 'Flete a Pagar',
    GRAINS: 'Granos/Especie',
    ID: 'Nro. de Cupo',
    KM_TO_TRAVEL: 'Kilómetros a recorrer',
    LOAD: 'Carga',
    LOADING_DATE: 'Fecha de carga',
    LOCALITY: 'Localidad',
    MANAGED_BY: 'Lote gestionado por:',
    NO_RECORDS: 'No se registran Cupos',
    ORIGIN: 'Procedencia de la mercadería',
    OVERALLOCATION_WARNING_ASSIGNED: 'Este Negocio ya tiene <b>{{ assigned }}</b> Cupo(s) asignado(s).',
    OVERALLOCATION_WARNING: 'Advertencia de potencial sobrecupeo, para un Negocio de <b>{{tons}}</b> toneladas.',
    PENDING: 'Esperando respuesta',
    PROVINCE: 'Provincia',
    QUALITY_DECLARATION: 'Declaración de Calidad',
    RECEIVED: 'Recibidos pendientes',
    REFRESH_STATUS_STOP: 'Refrescar estados',
    REJECTED: 'Rechazados',
    RENSPA: 'R.E.N.S.P.A.',
    RETURN_BODY: '¿Estás seguro de devolver el Cupo <code>{{slot}}</code> a <b>{{allocator}}</b>?. Esto significa que no vas a utilizarlo <span class="text-muted">(si el Cupo ya había sido asignado a un tercero, se retirará)</span>.<br><br>Esta acción no puede deshacerse.',
    RETURN: 'Devolver Cupo',
    RETURNED: 'Devueltos',
    TAKE: 'Solicitar/Tomar',
    TOGGLE_DASHBOARD: 'Tablero',
    UNAVAILABLE_BATCHES: 'No se encontraron lotes de <b>{{product}}</b> con Cupos pendientes de asignar.',
    VEHICLE_DATA: 'Datos del transporte',
    WAYBILL_FILE_HELP: 'Sólo se aceptan archivos PDF (Máx.: 4 MB).',
    WAYBILL_FILE: 'Carta de porte en blanco',
    WAYBILL_GENERATE: 'Generar Carta de Porte',
    WAYBILL_GENERATOR_DESCRIPTION: 'Confeccione la Carta de Porte de camiones rellenando el siguiente formulario.',
    WAYBILL_GENERATOR: 'Generador de Cartas de Porte',
    WAYBILL_INFO: 'Información para la Carta de Porte',
    WEIGHED_DESTINATION: 'La carga será pesada en destino',
    WITHDRAWN: 'Retirados',
    YES_RETURN: 'Si, devolver',
    RETURN_BATCH: 'Devolver todos los cupos',
    RETURN_BATCH_BODY: '¿Estás seguro de devolver estos <b>{{ quantity }} Cupo(s)</b> a <b>{{ allocator }}</b>?. Esto significa que no vas a utilizarlos <span class="text-muted">(si algún Cupo ya había sido asignado a un tercero, se retirará)</span>.<br><br>Esta acción no puede deshacerse.',
    RETURN_SELECTED: 'Devolver cupos seleccionados',
    DELETE_BATCH: 'Eliminar todos los cupos',
    DELETE_BATCH_BODY: '¿Estás seguro de eliminar estos <b>{{ quantity }} Cupo(s)</b>.<br><br>Tenga en cuenta que solo se eliminaran los cupos que no esten asignados',
    DELETE_SELECTED: 'Eliminar cupos seleccionados',
    // REFRESH_WAIT: 'Aguarde',
    ALREADY_EXISTS: '¡Cuidado! Los siguientes cupos ya existen dentro del sistema:',
    STOP: {
      DESCRIPTION: 'La plataforma puede mostrar en pantalla información del <a href="https://www.ssta.gob.ar/turnos/" target="_blank">sistema STOP</a> cuando esté disponible. Para ello, es necesario que nos proporciones el API KEY vinculado al CUIT de su Empresa.',
      HELP: 'No compartiremos este key con terceros.',
      LABEL: 'STOP API key',
      LINK: 'Integrar con STOP',
      NOT_CONNECTED: 'Para poder importar información es necesario proporcionar una API key.',
      TITLE: 'API KEY Sistema de turnos (STOP)'
    },
    ACTIONS: {
      ACCEPTED_BATCH_PARTIALLY: 'aceptó parcialmente el lote de Cupos.',
      ACCEPTED_BATCH: 'aceptó el lote de Cupos.',
      ASSIGNED_SLOT: 'asignó el Cupo a su Empresa.',
      CREATE_BATCH: 'generó el lote de Cupos.',
      REJECTED_BATCH: 'rechazó el lote de Cupos.',
      RETURNED_BATCH: 'devolvio el Cupo.',
      SENT_BATCH: 'reenvió el lote de Cupos a <b>{{company}}</b>.',
      WITHDREW_BATCH_PARTIALLY: 'retiró parcialmente el lote de Cupos.',
      WITHDREW_BATCH: 'retiró el lote de Cupos.',
    },
    TABLE_HEADER: {
      ASSIGNED_BY: 'Asignado por',
      ASSIGNED_SLOTS: 'Asignados',
      ASSIGNED_TO: 'Asignado a',
      BATCH_SLOTS: 'Cupos',
      PENDING_SLOTS: 'Pendientes',
      PRODUCT_ABBR: 'Prod.',
      RECEIVER: 'Destinatario / Destino'
    },
    STATUS: {
      ACCEPTED: 'Aceptado',
      EXPIRED: 'Vencido',
      SENT: 'Enviado',
      RECEIVED: 'Recibido',
      REJECTED: 'Rechazado',
      WITHDRAWN: 'Retirado',
      RETURNED: 'Devuelto',
      ASSIGNED: 'Asignado',
      ASSIGNED_PARTIALLY: 'Parcialmente Asignado',
      PENDING: 'Pendiente',
      STOP: {
        '1': 'Sin CTG',
        '2': 'Activado',
        '3': 'Descargado',
        '5': 'Arrivado'
      }
    },
    ACCEPT_MODAL: {
      TITLE: 'Aceptación de Cupos',
      DESTINATION: 'Destino',
      SLOTS_TO_ACCEPT: 'Cantidad de Cupos a aceptar:',
      REASONS: 'Motivos',
      REASONS_PLACEHOLDER: 'Ingresa los motivos por no aceptar el total (opcional)...',
      SIMPLER_QUESTION: '¿Quieres aceptar todos los Cupos y asignarlos a tu Empresa?',
      SIMPLER_QUESTION_NO: 'No, quiero aceptar menos Cupos...',
      SIMPLER_QUESTION_YES: 'Si, <b>{{company}}</b> será el titular de las Cartas de porte',
      ACCEPT: 'Aceptar {{quantity}} Cupo(s)'
    },
    ASSIGN_MODAL: {
      AVAILABLE_SLOTS: 'Cupos disponibles en este lote: <b>{{quantity}}</b>',
      DIRECT: 'Directo',
      ENTER_ANOTHER: 'Ingresar otro...',
      NO_COMPANIES: 'No hay otras Empresas disponibles.',
      QUANTITY: 'Cantidad de Cupos',
      TITLE: 'Asignación de Cupos',
      OBSERVATIONS_HELP: 'Los receptores de estos lotes recibirán estas mismas observaciones.',
      OBSERVATIONS_PLACEHOLDER: 'Instrucciones, comentarios...',
      PENDING_SLOTS: 'Cupos pendientes: <b>{{quantity}}</b>',
      TAKE: 'Asignar a mi Empresa',
      TAKE_HELP: 'Al asignar estos Cupos a tu Empresa significa que <b>{{company}}</b> será el titular de las Cartas de porte.'
    },
    REQUEST_SLOT_MODAL: {
      AVAILABLE_SLOTS: 'Cupos disponibles para solicitar: <b>{{quantity}}</b>',
      MAX_SLOTS: 'Cantidad máxima de cupos para solicitar: <b>{{quantity}}</b>',
      APPLIED_VOLUME: 'Volumen aplicado',
      ENTER_ANOTHER: 'Ingresar otro...',
      NO_COMPANIES: 'No hay otras Empresas disponibles.',
      QUANTITY: 'Cantidad de Cupos',
      TITLE: 'Solicitar Cupos',
      REQUESTED: 'Cupos solicitados',
      APPLICANT: 'Solicitante',
      RECIPIENT: 'Receptor',
      EXISTING_REQUEST_MESSAGE: 'Hay <strong>{{ quantity }}</strong> solicitudes asociadas a esta negociación. Con un total de <strong>{{ total }}</strong> cupos solicitados.',
      EXISTING_REQUEST_MESSAGE_CONTRACT: 'Hay <strong>{{ quantity }}</strong> solicitudes asociadas a este contrato. Con un total de <strong>{{ total }}</strong> cupos solicitados.'
    },
    REJECT_MODAL: {
      TITLE: 'Rechazar Cupos',
      DESCRIPTION: 'Opcionalmente, puedes incluir las razones del rechazo:',
      PLACEHOLDER: 'Ingresa las razones...',
      REJECT: 'Rechazar Cupos'
    },
    WITHDRAW_MODAL: {
      TITLE: '¿Estás seguro de retirar este lote?',
      DESCRIPTION: 'Opcionalmente, puedes compartir con <b>{{company}}</b> las razones por las que decidiste retirar este lote:'
    },
    ADD_MODAL: {
      ADDRESS: 'Dirección',
      TITLE: 'Agregar Cupos',
      DESTINATION: 'Destino',
      DESTINATION_DETAIL: 'Planta / Puerto / Terminal',
      DESTINATION_DETAIL_HELP: 'Incluya todos los datos necesarios para identificar el destino.',
      DESTINATION_DETAIL_PLACEHOLDER: 'Ingrese los detalles...',
      SLOT_IDS_HELP: 'Ingresa un código alfanumérico por línea',
      ENTER_DESTINATION: 'Ingresa un destino',
      DESTINATION_NOT_LISTED: '¿No encuentras el destino?',
      RECEIVER_NOT_LISTED: '¿No encuentras el Destinatario?'
    },
    DETAILS_MODAL: {
      TITLE: 'Detalle de Cupos',
      HOLDER: 'Titular C.P.',
      NUMBER: 'Número',
      CTG: 'Código de Trazabilidad de Granos',
      CTG_ABBR: 'C.T.G.',
      REQUEST_CTG: 'Solicitar C.T.G.',
      CHECK_STATUS: 'Chequear estado',
      WITHDRAW_SLOTS: 'Opcionalmente, puedes compartir las razones por el retiro de estos <b>{{quantity}}</b> Cupos:'
    },
    VIEWMODE: {
      COMPACT: 'Compacto',
      NORMAL: 'Normal'
    },
    REJECT_REQUEST_MODAL: {
      TITLE: 'Rechazar solicitud de cupos',
      DESCRIPTION: '¿Estás seguro de rechazar esta solicitud de cupos?',
    },
    WITHDRAW_REQUEST_MODAL: {
      TITLE: 'Retirar solicitud de cupos',
      DESCRIPTION: '¿Estás seguro de retirar esta solicitud de cupos?',
      SUBMIT: '',
    },
    DELETE_REQUEST_MODAL: {
      TITLE: '¿Estás seguro de eliminar esta solicitud de cupos?',
    },
    IMPORT: {
      BUTTON: 'Importar estados de STOP',
      DESCRIPTION: 'Actualiza los estados de STOP de tus cupos por medio de un archivo Excel <code>(.xls, .xlsx)</code>, puedes bajar una plantilla de ejemplo haciendo click en este <a href="/assets/docs/format_slots.xlsx" target="_blank" download=""><u>link</u></a>',
    }
  },
  BARTERS: {
    ADD_MANUAL: 'Carga manual',
    ADD_PRODUCT_HELP: '<b>Los campos a continuación son OPCIONALES</b>. Sólo completa la información que consideres relevante.',
    ADD_PRODUCT: 'Agregar producto',
    AMOUNT: 'Monto',
    BALANCE: 'Saldo',
    BARTER_PROPOSAL_ID: 'Propuesta de Canje #{{id}}',
    BARTER_PROPOSAL: 'Propuesta de Canje',
    BROKERS: 'Corredores',
    CREATE_AND_SEND: 'Crear y Enviar',
    CREATE: 'Crear Propuesta',
    DUE_ABBR: 'Vto.',
    EDIT_PRODUCT: 'Editar producto',
    EXCLUDED_COMPANIES: 'Quedan excluidas las siguientes Empresas por tener Propuestas activas: <b>{{companies}}</b>.',
    EXEMPT: 'Exento',
    INVOICE_NUMBER_HELP: 'Puedes especificar todas las facturas a cancelar con esta operación de canje, e incluso definir montos parciales.',
    MANAGERS: 'Gestores',
    MORE_DETAILS: 'Esto requiere más detalles',
    NEW_PROPOSAL: 'Nueva propuesta de Canje',
    NO_CUSTOMER: '<b>No hay Clientes en tu Red.</b> Para agregar Empresas <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">haz click aquí</a>.',
    NO_DISTRIBUTOR: '<b>No hay Distribuidoras en tu Red.</b> Para agregar Empresas <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">haz click aquí</a>.',
    NO_RECORDS: 'No se registran Canjes para esta Empresa.',
    NO_SUPPLIER: '<b>No hay Insumeras en tu Red.</b> Para agregar Empresas <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">haz click aquí</a>.',
    NO_TAX: 'Sin {{tax}}',
    NUMBER_PROPOSALS: '<b>{{quantity}}</b> propuesta(s)',
    OFFLINE: 'Canje offline',
    OPTIONAL_PROPOSALS: 'Puedes agregar uno o más productos para canje (especificando grano, calidad, período de entrega, procedencia).',
    PARTIAL: 'Parcial',
    REPLICATE: 'Selecciona una Órden del mercado para replicar sus condiciones:',
    SELF_MANAGED_HELP: 'Este Canje será gestionado por <b>{{name}}</b>, sin intermediarios.',
    SEND_NEW: 'Enviar nueva propuesta...',
    SEND: 'Enviar propuesta',
    SUMMARY: 'Mostrando <span class="quantity">{{barters}}</span> Canje(s) y sus <span class="quantity">{{proposals}}</span> Propuesta(s).',
    TO_BE_SETTLED: 'Monto a cancelar',
    TOTAL: 'Total facturado',
    ACCEPT_CONFIRM: {
      BODY: '<p>Al aceptar los términos del Canje, la confirmación y cierre estará a cargo de <b>{{company}}</b>.</p><div class="text-danger"><b>Importante:</b> La aceptación es en firme. Una vez realizada, NO PODRÁ RETIRARSE.</div>',
      TITLE: '¿Aceptás los términos?'
    },
    REJECT_MODAL: {
      TITLE: '¿Quieres rechazar esta propuesta de Canje?',
      BODY: 'Esto implica que no estás interesado en realizar este Canje. Esta acción no puede deshacerse.'
    },
    REMOVE_MODAL: {
      TITLE: '¿Quieres quitar esta producto de la Propuesta?',
      BODY: 'Si quieres incluirlo más tarde, tendrás que agregarlo nuevamente.'
    },
    STATUS: {
      ACCEPTED_LONG: '<b>Propuesta aceptada.</b> Los negocios están confirmados.',
      ACCEPTED: 'Propuesta aceptada',
      IN_PROGRESS_LONG: '<b>Propuesta en negociación.</b> Esperando a las contrapartes.',
      IN_PROGRESS: 'Propuesta en negociación',
      NEW_LONG: '<i class="material-symbols-rounded blinking">warning</i> <b>Nueva propuesta.</b> Revisa y/o completa la información necesaria.',
      NEW: 'Nueva propuesta',
      RECEIVED_LONG: '<i class="material-symbols-rounded blinking">warning</i> <b>Propuesta recibida.</b> Debes responder.',
      RECEIVED: 'Propuesta recibida',
      REJECTED_LONG: '<b>Propuesta rechazada.</b>',
      REJECTED: 'Propuesta rechazada',
      SENT_LONG: '<b>Propuesta enviada.</b> Esperando devolución de la contraparte.',
      SENT: 'Propuesta enviada'
    },
    WITHDRAW_MODAL: {
      TITLE: '¿Quieres retirar esta propuesta de Canje?',
      BODY: 'Esto implica que no estás interesado en realizar este Canje. Esta acción no puede deshacerse.'
    }
  },
  TRUCKS: {
    APPLIED: 'Aplicado',
    DESTINATION: 'Destino',
    DESTINATION_WEIGHT: 'Peso en destino',
    FEE: 'Tarifa',
    FEE_REFERENCE: 'Tarifa de referencia',
    GROSS: 'Bruto',
    GROSS_KG: 'Bruto: {{quantity}} <small>Kgs.</small>',
    NET: 'Neto',
    NET_KG: 'Neto: {{quantity}} <small>Kgs.</small>',
    NO_DATA: 'No se registran descargas.',
    ORIGIN_WEIGHT: 'Peso en origen',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Descarga(s)',
    TARE: 'Tara',
    TARE_KG: 'Tara: {{quantity}} <small>Kgs.</small>',
    TITLE: 'Descargas',
    TO_COVER: 'A recorrer',
    TRAILER: 'Acoplado',
    TRANSPORT: 'Transporte',
    TRUCK: 'Camión',
    UNLOADED: 'Descarga',
    UNLOADS_NUMBER: 'en {{quantity}} descarga(s)',
    WASTAGE: 'Mermas',
    WAYBILL: 'Carta de porte',
    ACTORS: {
      BUYING_BROKER: 'Corredor comprador',
      CARRIER: 'Transportista',
      CARRIER_BROKER: 'Intermediario del flete',
      COMMERCIAL_SENDER: 'Remitente comercial',
      DESTINATION: 'Destino',
      DRIVER: 'Chofer',
      HOLDER: 'Titular',
      INTERMEDIARY: 'Intermediario',
      RECEIVER: 'Destinatario',
      REPRESENTATIVE: 'Representante/Entregador',
      SELLING_BROKER: 'Corredor vendedor',
      TERM_MARKET: 'Mercado a Término'
    },
    CPE_ACTORS: {
      TITULAR_CARTA_DE_PORTE: 'Titular Carta de Porte',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Remitente Comercial Productor',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Remitente Comercial Venta Primaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Remitente Comercial Venta Secundaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Remitente Comercial Venta Secundaria 2',
      MERCADO_A_TERMINO: 'Mercado a Termino',
      CORREDOR_VENTA_PRIMARIA: 'Corredor Venta Primaria',
      CORREDOR_VENTA_SECUNDARIA: 'Corredor Venta Secundaria',
      REPRESENTANTE_ENTREGADOR: 'Represante Entregador',
      REPRESENTANTE_RECIBIDOR: 'Represante Recibidor',
      DESTINATARIO: 'Destinatario',
      DESTINO: 'Destino',
      EMPRESA_TRANSPORTISTA: 'Empresa Transportista',
      FLETE_PAGADOR: 'Flete Pagador',
      CHOFER: 'Chofer',
      INTERMEDIARIO_DE_FLETE: 'Intermediario de Flete'
    },
    WASTAGE_ITEMS: {
      DAMAGED: 'Granos dañados',
      FOREIGN_BODIES: 'Cuerpos extraños',
      HUMIDITY: 'Humedad',
      OTHER: 'Otros',
      TOTAL: 'Total de mermas',
      WEED: 'Chamicos'
    }
  },
  INVOICES: {
    ADD_COVERAGE: 'Incorporar otros conceptos de cobertura:',
    BALANCE: 'Balance',
    BILLING_IN: 'Facturación en {{unit}}',
    CANCEL_BARTER: '¿Cancelar con un Canje?...',
    CONCEPT_ABBR: 'Con.',
    CONCEPT: 'Concepto',
    COVER_BARTER: 'Cubrir con un Canje',
    COVERAGE: 'Cobertura',
    COVERED_TOTAL: 'Cubierto',
    COVERED: 'Cubierta',
    CREATE: 'Cargar nueva factura',
    DESCRIPTION: 'Descripción',
    EDIT_COVERAGE: 'Editar cobertura',
    IMPORT: 'Importar Facturas',
    INVOICES_IN: 'Facturas en {{unit}}',
    INVOICES_NUMBER: 'sobre {{quantity}} factura(s)',
    ISSUED_TO: 'Emitido a',
    NET: 'Neto',
    NO_DATA: 'No se registran facturas.',
    NOT_LISTED: '¿No encuentras la factura?',
    PARTIALLY_COVERED: 'Cubierto parcialmente',
    PERCENTAGE_ABBR: 'Por.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Factura(s)',
    TAXED: 'Gravado',
    TITLE: 'Facturas',
    IMPORTED_BY: 'Importado por'
  },
  FIXATIONS: {
    DELETE: '¿Estás seguro de eliminar esta fijación?',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Fijacion(es)',
    TITLE: 'Fijaciones',
    REQUEST_MODAL: {
      TITLE: 'Enviar solicitud de fijación',
      MANAGE: 'Gestionar solicitud',
      REQUEST_FIXATIONS: 'Solicitudes de Fijación',
      REQUEST: 'Solicitar',
      REPLY: 'Responder',
      OBSERVATIONS_HELP: 'Los receptores de esta solicitud recibirán estas mismas observaciones.',
      LAST_OBSERBATION: 'Última observacion:',
      ALERT_LIMIT_OF_VOLUME: 'Se excede en la cantidad de toneladas que se pueden solicitar para este contrato.'
    },
    REQUEST_STATUS: {
      WAITING_RECEIPT: 'Esperando Respuesta',
      WAITING_COMPANY: 'Esperando Respuesta',
      WAITING_COUNTERPART: 'Esperando Contraparte',
      ACCEPTED: 'Aceptada',
      REJECTED: 'Rechazada',
    }
  },
  WAYBILLS: {
    NO_DATA: 'No hay Cartas de Porte para mostrar.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Carta(s) de porte',
    TITLE: 'Cartas de porte',
    ACTORS: {
      CHOFER: 'Chofer',
      CORREDOR_VENTA_PRIMARIA: 'Corredor Venta Primaria',
      CORREDOR_VENTA_SECUNDARIA: 'Corredor Venta Secundaria',
      DESTINATARIO: 'Destinatario',
      DESTINO: 'Destino',
      EMPRESA_TRANSPORTISTA: 'Empresa Transportista',
      FLETE_PAGADOR: 'Flete pagador',
      INTERMEDIARIO_DE_FLETE: 'Intermediario de flete',
      MERCADO_A_TERMINO: 'Mercado a Término',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Remitente Comercial Productor',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Rte. Comercial Venta Primaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Rte. Comercial Venta Secundaria 2',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Rte. Comercial Venta Secundaria',
      REPRESENTANTE_ENTREGADOR: 'Representante entregador',
      REPRESENTANTE_RECIBIDOR: 'Representante recibidor',
      TITULAR_CARTA_DE_PORTE: 'Titular Carta de Porte',
      OBSERVACIONES: 'Observaciones'
    }
  },
  CPE: {
    NO_DATA: 'No hay Cartas de Porte Electrónicas para mostrar.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Carta(s) de porte',
    TITLE: 'Cartas de Porte Electrónicas',
    ACTORS: {
      CHOFER: 'Chofer',
      CORREDOR_VENTA_PRIMARIA: 'Corredor Venta Primaria',
      CORREDOR_VENTA_SECUNDARIA: 'Corredor Venta Secundaria',
      DESTINATARIO: 'Destinatario',
      DESTINO: 'Destino',
      EMPRESA_TRANSPORTISTA: 'Empresa Transportista',
      FLETE_PAGADOR: 'Flete pagador',
      INTERMEDIARIO_DE_FLETE: 'Intermediario de flete',
      MERCADO_A_TERMINO: 'Mercado a Término',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Remitente Comercial Productor',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Rte. Comercial Venta Primaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Rte. Comercial Venta Secundaria 2',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Rte. Comercial Venta Secundaria',
      REPRESENTANTE_ENTREGADOR: 'Representante entregador',
      REPRESENTANTE_RECIBIDOR: 'Representante recibidor',
      TITULAR_CARTA_DE_PORTE: 'Titular Carta de Porte Electrónica'
    },
    TRANSPORTE: {
      TITLE: 'Datos del Transporte',
      TRANSPORTISTA_CUIT: 'Transportista CUIT',
      CHOFER_CUIT: 'Chofer CUIT',
      DOMINIO: 'Dominio',
      FECHA_PARTIDA: 'Fecha de partida',
      KMS_RECORRER: 'Kms. a recorrer',
      TARIFA_REF: 'Tarifa referencia',
      TARIFA: 'Tarifa',
    },
    ORIGIN: {
      PLANTA: 'Planta'
    }
  },
  LANDING: {
    MAIN: 'Comienza a trabajar de un modo ágil y seguro, con trazabilidad y sostenibilidad.',
    SECONDARY: 'Bienvenido a la plataforma que<br>facilita el acceso al crédito.',
    TAGLINE: 'Juntos en <span class="brand-color">la Evolución</span>',
    COMMUNITY: 'Comunidad',
    MANAGEMENT: 'Gestión',
    FUNDING: 'Financiamiento',
    food: {
      HEADLINE: '<b>Bienvenido</b><br>al mercado mayorista digital para comprar y vender alimentos!',
      FEATURE_01: 'Llega a más clientes',
      FEATURE_02: 'Opera cuando y donde quieras',
      FEATURE_03: 'Fácil y práctico'
    },
    ar: {
      HEADLINE: '<b>Bienvenido</b><br>a la plataforma que te permite comprar, negociar y vender granos y subproductos.',
      FEATURE_01: 'Opera cuando y donde quieras',
      FEATURE_02: 'Integramos a toda la cadena comercial',
      FEATURE_03: 'Fácil y segura'
    },
    br: {
      HEADLINE: '<b>Bienvenido</b><br>a la plataforma que te permite comprar, negociar y vender granos y subproductos.',
      FEATURE_01: 'Opera cuando y donde quieras',
      FEATURE_02: 'Integramos a toda la cadena comercial',
      FEATURE_03: 'Fácil y segura'
    },
    pt: {
      HEADLINE: '<b>Bienvenido</b><br>a la plataforma que te permite comprar, negociar y vender granos y subproductos.',
      FEATURE_01: 'Opera cuando y donde quieras',
      FEATURE_02: 'Integramos a toda la cadena comercial',
      FEATURE_03: 'Fácil y segura'
    }
  },
  IMPORTER: {
    ACCEPTED_FORMATS: 'Los formatos admitidos son:',
    ADD_CONTRACT: 'Agregar Contrato',
    CONTRACT_NUMBER_HELP: 'Identificador único de Contrato.',
    CONTRACT_NUMBER: 'Nro. de Contrato',
    DETAILS: 'Detalles',
    DROP_HELP: 'También puedes soltar archivos en esta área para subir contenido nuevo.',
    EDITING_CONTRACT: 'Editando Contrato: {{id}}',
    ERROR_INDEX: '(Error: {{index}})',
    ERROR: 'Error',
    ERRORS_SUMMARY: '¡No se puede importar archivo(s)! <span class="quantity">{{files}}</span> error(es) en <span class="quantity">{{errors}}</span> archivo(s).',
    ERRORS: '({{errors}} error(es))',
    HIDE_FILE_DETAILS: 'Ocultar detalles del archivo',
    INPUT: 'Entrada',
    INVALID_FILETYPE: "El tipo de archivo subido es inválido.",
    LINE: 'Línea',
    NO_COMPANIES: '<b>No hay Empresas en tu red.</b> <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">Haz click aquí para agregar</a>.',
    NOT_INPUT_AVAILABLE: "No disponible",
    SELECT_FILES: 'Selecciona los archivos que quieras importar:',
    SHOW_ALL_FIELDS: 'Mostrar todos los campos',
    SHOW_ERROR_FIELDS: 'Mostrar campos con errores',
    SHOW_FILE_DETAILS: 'Mostrar detalles del archivo',
    SHOW_MORE_ERRORS: 'Mostrar más errores para este archivo...',
    SOURCE_FILE: 'Archivo fuente',
    STATUSES: 'Estado de las importaciones',
    TITLE: '<span class="material-symbols-rounded">cloud_upload</span> Importar...',
    TRY_AGAIN: 'Prueba de nuevo...',
    UPDATED: 'Actualizado',
    VALUE: 'Valor',
    WARNING: {
      CONTRACT_APPLIED_VOLUME_GT_110: 'El volumen aplicado es mayor al 110% del total',
      CONTRACT_FIXED_VOLUME_GT_110: 'El volumen fijado es mayor al 110% del total',
      CONTRACT_INVOICED_VOLUME_GT_APPLIED: 'El volumen liquidado es mayor al aplicado',
      CONTRACT_INVOICED_VOLUME_GT_FIXED: 'El volumen liquidado es mayor al fijado',
      CONTRACT_PRICE_LT_100_USD: 'El precio es menor a USD 100',
      CONTRACT_PRICE_LT_10000_ARS: 'El precio es menor a $ 10.000',
      CONTRACT_VOLUME_0: 'El volumen es menor o igual 0',
      FIXATION_PRICE_LT_100_USD: 'Fijado por menos de USD 100',
      FIXATION_PRICE_LT_10000_ARS: 'Fijado por menos de $ 10.000',
      FIXATION_VOLUME_LT_0T: 'El volumen fijado es menor o igual 0',
      NO: 'Sin inconsistencias',
      TITLE: 'Potenciales inconsistencias',
      UNLOAD_APPLIED_GT_NET: 'Volumen aplicado mayor al peso neto',
      YES: 'Con inconsistencias'
    },
    SOURCE: {
      '1': 'API',
      '2': 'Archivo',
      '3': 'Manual',
      '4': 'AFIP WS',
      '5': 'Archivo'
    },
    STATUS: {
      '1': '',
      '2': 'Error de formato',
      '3': 'En cola',
      '4': 'Procesando...',
      '5': '',
      '6': 'Reintentando...',
      '7': 'Error',
      '8': 'Formato validado'
    }
  },
  SIGNATURES: {
    ACTION: 'Acción',
    ADD_FILES: 'Añadir documentos al Sobre',
    ADD_MORE_SIGNERS: '¿Necesitas agregar firmantes?...',
    ADD_SIGNER: 'Agregar Firmante',
    CREATE_ENVELOPE: 'Crear Sobre',
    DEFINE_SIGNERS: 'Definir firmantes',
    DEFINE_SIGNERS_CONFIRM: 'Una vez definidos los Firmantes del Sobre no podrás volver a modificarlos.',
    DEFINE_SIGNERS_HELP: 'Selecciona del listado de Firmantes de tu Empresa quiénes deben firmar este Sobre.',
    DOCUMENTS: 'Documentos en el Sobre',
    EDIT_SIGNER: 'Editar Firmante',
    ENVELOPE: 'Sobre #{{id}}',
    EXPIRATION_DATE: 'Fecha de caducidad',
    EXPIRES: 'Vence',
    HISTORY: 'Historial',
    LAST_MODIFIED: 'Último cambio',
    LINKED_TO: 'Vinculado a',
    MESSAGE: 'Mensaje',
    MESSAGE_PLACEHOLDER: 'Escriba un mensaje...',
    NOT_SIGNED: 'Aún no firmó',
    NO_DATA: 'No hay Sobres para mostrar.',
    NO_SIGNERS: 'Tu Empresa no tiene Firmantes cargados.',
    PARTIALLY_SIGNED: 'Firmado parcialmente',
    RECIPIENTS_ADD_MY_COMPANY: 'Agregar mi Empresa',
    RECIPIENTS_COMPANIES: 'Empresas firmantes del Sobre',
    RECIPIENTS_HELP: 'Cada Empresa definirá luego quienes tienen autorización y competencia para firmar este Sobre.',
    REQUIRED_SIGNERS: '¿Quiénes deben firmar?',
    SEEN: 'Visto',
    SENDER: 'Remitente',
    SIGN: 'Firmar',
    SIGNED: 'Firmado',
    SIGNERS: 'Firmantes',
    SIGNERS_DESCRIPTION: 'Gestiona la información de las personas físicas con poder de firma de tu Empresa.',
    SIGNER_AUTHORIZATION: 'Poder del firmante',
    SIGNER_AUTHORIZATION_HELP: 'Documento que acredite el poder de firma de esta persona a nombre de la Empresa. Este documento estará disponible para validación de las contrapartes.',
    SIGNER_AUTHORIZATION_VIEW: 'Ver Poder',
    SIGNS_PENDING: 'Faltan {{quantity}} firmas.',
    SUBJECT: 'Asunto',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Sobre(s) de <span class="quantity">{{total}}</span>',
    TIMESTAMP: 'Hora',
    USER: 'Usuario',
    RELATED: {
      PLACEHOLDER: 'Crea un sobre con documentación relacionada para firmar.',
      TITLE: 'Sobres relacionados'
    },
    STATUS: {
      CANCELED: 'Cancelado',
      COMPLETED: 'Completado',
      DEFINE_SIGNERS: 'Tu Empresa no definió Firmantes',
      NOT_SENT: 'Sin enviar',
      PENDING: 'Firmas pendientes',
      SENT: 'Este Sobre ya fue enviado. Los Firmantes han sido notificados a sus correos.',
      WAITING_SIGNERS: 'Esperando que definan Firmantes'
    }
  },
  ATTACHMENTS: {
    ERROR: 'Esta subida falló.',
    HEADER: 'Adjuntos',
    PLACEHOLDER: 'Arrastra y suelta archivos aquí para adjuntarlos.',
    TITLE: 'Eliminar archivo',
    MODAL: {
      TITLE: '¿Estás seguro de eliminar este archivo?'
    },
    PRIVATE: {
      TITLE: '¿Qué visibilidad deben tener estos archivos?',
      PUBLIC: 'Visible para todos',
      PUBLIC_HELP: '<h5>Visible para todos</h5>Todo Usuario con acceso a este contenido, podrá acceder al archivo.',
      PRIVATE: 'Sólo para mi Empresa',
      PRIVATE_HELP: '<h5>Sólo para mi Empresa</h5>El archivo sólo estará disponible para <b>Usuarios de tu Empresa</b>.'
    }
  },
  CONTRACTS: {
    APPLICATIONS: 'Aplicaciones',
    BUSINESS_DAYS: 'En <b>{{days}}</b> día(s) hábil(es)',
    CALENDAR_DAYS: 'En <b>{{days}}</b> día(s) corrido(s)',
    COMPANY_NOT_FOUND: '<b>Error!</b> Tu Empresa debe intervenir en el Negocio a generar.',
    CONFIRM_PARTICIPANTS: 'Confirma los intervinientes.',
    FIXATION_DEADLINE: 'Fijar hasta:',
    FIXATIONS_NUMBER: 'en {{quantity}} Fijacion(es)',
    FIXATIONS: 'Fijaciones',
    FIXED: 'Fijado',
    GENERATE_TRADE: 'Generar un Negocio relacionado',
    INFORMANT: 'Informante',
    INVOICING: 'Facturación',
    NO_DATA: 'No se registran Contratos.',
    NO_FIXATION_DATA: 'No se registran Fijaciones',
    NOT_AVAILABLE: 'El Contrato no existe o no tiene permisos para verlo.',
    PARTIAL_PAYMENT: 'Pago parcial',
    PREPARING: 'Preparando Contrato...',
    SLATE_PRICE_HELP: 'Sólo si difiere del precio de fijación.',
    SLATE_PRICE: 'Precio de pizarra',
    SLATE: 'Pizarra',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Contrato(s)',
    TOLERANCE: 'Tolerancia',
    WEIGHTED_AVERAGE: 'Promedio ponderado',
    DIFF: {
      'delivery.date_from': 'Período (Desde)',
      'delivery.date_to': 'Período (Hasta)',
      'price.type': 'Tipo de precio',
      'price.unit': 'Moneda',
      'price.value': 'Precio',
      'quantity.type': 'Tipo de volúmen',
      'quantity.unit': 'Unidad de volúmen',
      'quantity.value': 'Volúmen',
      NO: 'Sin diferencias',
      TITLE: 'Diferencias Contrato/Negocio:',
      YES: 'Con diferencias'
    },
    IMPORT: {
      BUTTON: 'Importar contratos',
      DESCRIPTION: 'Sube tus contratos por medio de un archivo Excel <code>(.xls, .xlsx)</code>'
    }
  },
  TRACKER: {
    BATCH_ID: 'Id. de Lote',
    EVENT: 'Evento',
    GALLERY: 'Galería',
    INFORMATION: 'Información',
    RELATED_EVENTS: 'Eventos relacionados',
    EVENTS: {
      APPLICATION: 'Aplicación de agroquímico',
      HARVEST: 'Cosecha',
      IRRIGATION: 'Riego',
      LOAD: 'Carga',
      QUALITY_CONTROL: 'Control de calidad',
      SOWING: 'Siembra',
      STORAGE: 'Almacenamiento',
      SUPPLIES_SALE: 'Venta de insumos',
      TRADE: 'Operación de compra-venta',
      TRANSPORTATION: 'Transporte',
      UNLOAD: 'Descarga',
      WEED_CONTROL: 'Control de maleza'
    }
  },
  FUTURES: {
    ADD: 'Agregar operación',
    CONDITION: 'Condición',
    EXCHANGE_RATE: 'Tipo de cambio',
    EXCHANGE_RATE_HELP: 'Tipo de cambio (Banco Nación, vendedor) al día anterior para informar las operaciones (<a href="http://biblioteca.afip.gob.ar/dcp/REAG01003421_2012_12_20" target="_blank">RG 3421</a>).',
    TITLE: 'Futuros y Opciones',
    NO_DATA: 'No se registran operaciones.',
    NO_DELIVERY: 'Sin entrega',
    OPERATION_DATE: 'Fecha de la operación',
    OPERATOR: 'Operador',
    POSITION: 'Posición',
    POSITION_PLACEHOLDER: 'mm/aaaa',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> operacione(s)',
    VOLUME: 'Volumen',
    TYPES: {
      1: 'Futuro',
      2: 'Opción'
    }
  },
  LINKER: {
    CONTRACTS: 'Contrato(s):',
    DESCRIPTION: 'Esta herramienta permite vincular <b>Contratos</b> con sus respectivos <b>Negocios</b> y viceversa.',
    DESCRIPTION_NEGOTIATION: 'Esta herramienta permite vincular <b>Negocios</b> entre sí.',
    DESCRIPTION_CONTRACT: 'Esta herramienta permite vincular <b>Contratos</b> entre sí.',
    DESCRIPTION_BATCH: 'Esta herramienta permite vincular <b>Cupos</b> a un <b>Negocio</b> y viceversa.',
    DESCRIPTION_BATCH_CONTRACT: 'Esta herramienta permite vincular <b>Cupos</b> a un <b>Contrato</b>',
    LINK: 'Vincular Contrato y Negocio',
    LINK_NEGOTIATION: 'Vincular Negocios',
    LINK_CONTRACTS: 'Vincular Contratos',
    LINK_BATCH: 'Vincular Cupos al Negocio',
    LINK_BATCH_CONTRACT: 'Vincular Cupos al Contrato',
    LINK_BATCH_BUTTON: 'Vincular a un Negocio',
    LINK_BATCH_BUTTON_CONTRACT: 'Vincular a un Contrato',
    NO_CONTRACTS: 'No se encontraron <b>Contratos</b> para vincular.',
    NO_TRADES: 'No se encontraron <b>Negocios</b> para vincular.',
    SELECT_CONTRACT: 'Selecciona el <b>Contrato</b> de la lista que corresponde a este <b>Negocio</b>:',
    SELECT_MIRROR_CONTRACT: 'Selecciona el <b>Contrato</b> espejo de la lista que corresponde a este <b>Contrato</b>:',
    SELECT_TRADE: 'Selecciona el <b>Negocio</b> de la lista que corresponde a este <b>Contrato</b>:',
    SELECT_TRADE_NEGOTIATION: 'Selecciona el <b>Negocio</b> de la lista que quieras vincular:',
    SELECT_TRADE_BATCH: 'Selecciona el <b>Negocio</b> de la lista que corresponde a estos <b>Cupos</b>.<br><small>Estos son Negocios relacionados con <b>{{counterpart}}</b>.</small>',
    SELECTED_CONTRACT: 'Contrato seleccionado:',
    SELECTED_TRADE: 'Negocio seleccionado:',
    TITLE: 'Asistente de vinculación',
    TRADES: 'Negocio(s):',
    UNLINK: 'Desvincular',
    UNLINK_TITLE: '¿Estás seguro de desvincularlos?',
    UNLINK_BODY: 'Se desvincularán el Negocio: <samp>#{{negotiation}}</samp> y el Contrato: <samp>{{contract}}</samp>. Puedes volver a vincularlos nuevamente más tarde.',
    UNLINK_BODY_BATCH: 'Se desvincularán el Negocio: <samp>#{{negotiation}}</samp> de estos Cupos. Puedes volver a vincularlos nuevamente más tarde.',
    UNLINK_BODY_BATCH_CONTRACT: 'Se desvincularán el Contrato: <samp>#{{contract}}</samp> de estos Cupos. Puedes volver a vincularlos nuevamente más tarde.',
    YES_UNLINK: 'Si, desvincular'
  },
  FINTECH: {
    ADD_FUNDER: 'Agregar Financiador',
    ADD_SIGNER: 'Agregar firmante...',
    ADMIN: 'Administración',
    AFFIDAVIT: '<b>Importante:</b> Declaro bajo protesta de decir verdad que los datos, información y/o documentación cargados por el usuario a los efectos de la presente solicitud, son verídicos y vigentes. Respondiendo el Solicitante por la validez, vigencia y exactitud de todos sus términos.',
    AMOUNT_PENDING: 'A definir',
    AMOUNT_REQUESTED_ABBR: 'Línea solicitada',
    AMOUNT_REQUESTED: 'Línea de crédito solicitada',
    APPLICANT: 'Solicitante',
    APPLICATION_APPLICANT: 'Solicitud de crédito > {{applicant}}',
    APPLICATION_ID: 'Solicitud de crédito #{{id}}',
    APPLICATION_PRODUCT_HELP: 'Cualquier cambio en estos valores sólo afectará al Producto de esta Solicitud en particular.',
    APPLICATION_PRODUCT: 'Condiciones del Producto para esta Solicitud:',
    APPLICATION_START: 'Iniciar Solicitud(es)',
    APPLICATIONS: 'Solicitudes',
    APPROVED_LOC: 'Línea de crédito aprobada',
    ASSIGNMENT: 'Cesión',
    BUYER_HELP: 'Selecciona el Comprador del Contrato.',
    CALCULATED_EXCHANGE: 'Tipo de cambio calculado: {{rate}}',
    CALCULATION_AS: 'Cálculo al {{date}}',
    COMMITTEE: 'Comité',
    CONDITIONS: 'Condiciones',
    CONTRACT: 'Contrato forward',
    CREDIT_GRANTED: 'Crédito otorgado',
    CREDIT_LINE_MAX: 'Margen Empresa',
    CREDIT_LINE: 'Línea de crédito',
    CREDIT_LINES_SCHEME_EDIT: 'Editar esquema...',
    CREDIT_LINES_SCHEME: 'Esquema de líneas de crédito',
    CREDIT_LINES_UPLOAD: 'Para definir lineas de crédito arrastre un XLS con una columna para {{label}} seguida de otra con el valor de la línea correspondiente.',
    CREDIT_LINES: 'Líneas de crédito',
    DATE_FROM: 'Desde',
    DATE_TO: 'Hasta',
    DELIVERY_PERIOD_HELP: 'Según el contrato forward presentado en garantía.',
    DOA_APPROVERS_BUTTON: 'Notificar al primer Aprobador',
    DOA_APPROVERS_HELP: 'Arrastra los Usuarios para definir los Aprobadores y el orden según Delegación de Autorización (DOA).',
    DOA_APPROVERS_NO_DATA: 'No hay Usuarios definidos en el <a href="/company/{{companyId}}/team">equipo de tu Empresa</a>.',
    DOA_APPROVERS_NO_USERS: '¿Faltan Usuarios?',
    DOA_APPROVERS_PLACEHOLDER: 'Arrastra y suelta aquí...',
    DOA_APPROVERS: 'Aprobadores',
    DOA_INFO_HELP: 'Esta información será sólo visible para los Aprobadores.',
    DOA_INFO: 'Información adicional',
    DOA_NEXT_APPROVER: 'Aprobador siguiente',
    DOA_ORDERLY_DELEGATION: 'Delegación ordenada',
    DOA_PENDING_APPROVER: 'Aprobador pendiente',
    DOA_PREVIOUS_APPROVER: 'Aprobador anterior',
    DOA_REVIEW: 'Revisión',
    DOA_YOUR_VOTE: 'Tu voto',
    DOCUMENTATION: 'Documentación',
    DUPLICATES: 'Duplicados ignorados',
    ECHEQ: 'eCheq',
    EDIT_FUNDER: 'Editar Financiador',
    ENGINE_CREDIT: 'Valorización de motor:',
    ENGINE_SCORING: 'Scoring de motor: <b>{{scoring}}</b>',
    ESTIMATES_HELP: 'Estos valores son estimados. El cálculo final de los intereses, impuestos y gastos se realizará en función de la fecha en que efectivamente se acrediten los fondos para cancelar el crédito.',
    ESTIMATES: 'Estimaciones',
    EXPENSES: 'Gastos',
    EXPORT_APPLICATIONS: 'Exportar solicitudes',
    EXPORT_BODY: 'La exportacion de las solicitudes seleccionadas <b>va a tomar unos minutos</b>. Cuando esté lista, la plataforma te enviará un email.',
    FUNDER: 'Financiador',
    FUNDERS: 'Financiadores',
    GRANT: 'Otorgar',
    GRANTING: 'Otorgamiento',
    INDICATOR: 'Indicador',
    INTEREST_RATE_DETAILS: 'Explicación de tasa',
    INTEREST_RATE_HELP: 'Tasa Anual Efectiva, incluyendo interés nominal, gastos y comisiones.',
    INTEREST_RATE_LABEL: 'Etiqueta de la tasa',
    INTEREST_RATE: 'Tasa de interés',
    INTEREST: 'Intereses',
    INVALIDS: 'No válidos',
    INVOICE: 'Factura del insumo/servicio',
    LOANS: 'Créditos',
    MANAGED_BY: 'Gestionada por:',
    MANAGER: 'Gestor',
    MANAGMENT: 'Administración Fintech',
    MANUAL_SCORING: 'Scoring manual',
    NEW_SCREENING: 'Nuevo Screening',
    NO_DATA: 'No se registra ninguna solicitud.',
    NO_FUNDERS_HELP: '¿Estás interesado en ofrecer productos financieros?...',
    NO_FUNDERS: 'Tu Empresa no tiene Financiadores asociados.',
    NOTICE_HELP: 'Sólo cuando el Contrato no incluya cláusula de cesión.',
    NOTICE_LABEL: 'Solicitar comprobante de notificación de cesión',
    NOTICE: 'Notificación de cesión',
    OBSERVATIONS_HELP: 'Estas observaciones serán visibles para los usuarios.',
    OBSERVATIONS_PRIVATE: 'No serán visibles para otras Empresas.',
    ONLY_FUNDABLES: 'Sólo financiables',
    PAYMENT_FREQUENCY: 'Frecuencia de pago',
    // PENDING_PRODUCTS: '<b>{{quantity}}</b> producto(s) pendiente(s)',
    PENDING_REVIEW: 'Pendiente de revisión',
    POLICY_INVOICE_HELP: 'Puedes subir más de una factura, <b>recuerda subir todas</b> las que estás financiando.',
    POLICY_INVOICE: 'Factura de la póliza',
    PRE_APPROVED: 'Pre-aprobado',
    PROCESSED_DATE: 'Procesado: {{date}}',
    PROCESSED: 'Elementos en este lote: {{quantity}}',
    PRODUCT_CURRENCY: 'Moneda del producto',
    PRODUCT_DETAILS: 'Detalles del producto de {{company}}',
    PRODUCT_HELP: 'Puedes seleccionar más de uno.',
    QUALIFICATION_ID: 'Calificación #{{id}}',
    QUALIFICATION_START: 'Iniciar Calificación',
    QUALIFICATION: 'Calificación',
    QUALIFICATIONS: 'Calificaciones',
    QUALIFIED: 'Calificado',
    QUALIFY: 'Calificar',
    REACTIVATE_BODY: 'Al reactivar esta Solicitud reinicia el proceso creando una Solicitud relacionada que hereda toda la información capturada en esta. ¿Estás seguro?.',
    REACTIVATE_CHILD: '<b>Revisiones</b>:',
    REACTIVATE_PARENT: 'Esta es una <b>revisión</b> de',
    REACTIVATE: 'Reactivar la solicitud',
    RECEIPT: 'Comprobante de transferencia',
    REGISTERED: 'Registrado',
    REPORT_BODY: 'La generación del reporte para las solicitudes seleccionadas <b>va a tomar unos minutos</b>. Cuando esté listo, la plataforma te enviará un email.',
    REPORT_FUNDERS_ONLY: 'Esta funcionalidad está sólo disponible para Financiadores.',
    REQUESTED_BY: 'Solicitada por:',
    REQUESTED: 'Solicitado',
    RETRY_FORCED_HELP: '<p>Se intentará reprocesar esta Calificación.</p><p class="text-warning">De encontrarse un motivo de rechazo, se forzará la Solicitud a <b>revisión manual</b>.</p>',
    RETRY_HELP: 'Se intentará reprocesar esta Calificación.',
    REVIEWED_BY: 'Revisada por:',
    SCORING_APPROVED: 'Calificación aprobada',
    SCORING_COMMENTS: 'Comentarios sobre el Scoring',
    SCORING_DATA: 'Datos del Scoring',
    SCORING_IN_PROGESS: 'En curso',
    SCORING_REJECTED: 'Calificación rechazada',
    SCORING_WITHDRAWN: 'Calificación retirada',
    SCORING: 'Scoring',
    SCREENING_BATCHES: 'Lotes',
    SCREENING_DESCRIPTION: 'Nuestro proceso de Screening permite <b>determinar si una Empresa cumple con los requerimientos mínimos</b> para ser potencial candidato a productos financieros.',
    SCREENING_FISCAL_ID: 'Números de {{fiscal_id}}',
    SCREENING_HELP: '<b>El resultado es en el momento</b> y el único dato necesario es el número de {{fiscal_id}}. Puedes realizar un Screening de múltiples empresas simultaneamente.',
    SCREENING_INVALID: 'Ingresa al menos un {{fiscal_id}} válido.',
    SCREENING_MAX: 'Cantidad máxima permitida por lote: {{max}}',
    SCREENING_SELECT: 'Elige un lote de Screening para ver los resultados...',
    SCREENING: 'Screening',
    SEARCH_ID: 'Buscar por {{id}}...',
    SIGNERS_HELP: 'Selecciona a los firmantes autorizados de <b>{{company}}</b>.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> solicitud(es) de <span class="quantity">{{total}}</span>',
    TAXES: 'Impuestos',
    UNIQUES: 'Únicos a procesar',
    VALID_UNTIL: 'Válida hasta:',
    VALIDATE: 'Validar',
    VAT: 'I.V.A.',
    VIEW_APPLICATION: 'Ver solicitud',
    WITHDRAWN_BY: 'Retirada por:',
    WITHDRAWN_QUALIFICATION: 'Al retirar la Calificación, dejará de estar visible para el Gestor. Es posible restaurarla a su estado original más adelante.',
    WITHDRAWN_REASON_HELP: 'Los motivos serán visibles para todas las partes involucradas.',
    WITHDRAWN_REASON: 'Motivos',
    ADDITIONALS: {
      HELP: 'Esta calculadora es una simulación orientativa.',
      TITLE: 'Estimación de adicionales'
    },
    SCORING_XLS_DATA: {
      CTA: '¿Quieres aplicar estos datos?',
      DESCRIPTION: 'Detectamos la siguiente información en un archivo adjunto:',
      TITLE: 'Datos encontrados'
    },
    QUALIFICATION_STATUS: {
      0: 'Pendiente',
      1: 'Procesando',
      2: 'Aprobada',
      3: 'Error',
      4: 'En revisión',
      5: 'Rechazada',
      7: 'Obteniendo fuentes'
    },
    STEPS: {
      '1': 'Paso 1',
      '2': 'Paso 2',
      '3': 'Paso 3',
      '4': 'Paso 4',
      '5': 'Paso 5'
    },
    APPLY: {
      ADDITIONAL_ACTIVITIES_VOLUME: 'Ingreso neto anual aproximado de estas actividades.',
      ADDITIONAL_ACTIVITIES: 'Actividades adicionales',
      ALL_SET: 'Todo listo! No es necesario ningún dato adicional para continuar.',
      APPLICANT_RECIPIENT_TOGGLE: 'Especificar otra Empresa.',
      APPLICANT_RECIPIENT: 'El receptor de los fondos será el <b>Solicitante</b>.',
      AUTHORIZATION_REQUIRED: 'Es necesaria la <b>validación de identidad</b> del Solicitante o Representante legal.',
      CIEC_REQUIRED: 'Para consultar reportes de crédito, es necesario contar con <b>clave CIEC vigente</b> del Solicitante.',
      CROPS_HELP: 'Ingresa la información de tu plan productivo para el Ciclo Agrícola que deseas financiar.',
      CROPS: 'Cultivos',
      FILTERS: 'Filtros:',
      FISCAL_ID_HELP: '{{fiscal_id}} del Solicitante (sujeto de crédito a calificar).',
      NEW: 'Solicitar un crédito',
      NO_PRODUCTS: 'Lamentablemente, no tenemos productos financieros disponibles para esta selección en este momento.',
      PREVIOUS_CROPS_LOADED: 'Ya existe un plan productivo cargado con fecha <b class="text-uppercase">{{date}}</b>, puedes actualizarlo si es necesario.',
      PREVIOUS_CROPS_OPTION: '<span class="material-symbols-rounded">agriculture</span>&nbsp;&nbsp;<span class="text-uppercase">{{date}}</span> <span class="text-muted">({{fields}} lotes)</span>',
      PREVIOUS_CROPS: 'Se encontraron planes productivos anteriores',
      REQUEST_AUTHORIZATION: 'Solicitar validación',
      REQUEST_CIEC_HELP: 'La clave CIEC (clave de identificación electrónica confidencial) debe ser proporcionada por la <b>Persona física</b> o el <b>Apoderado</b> de la Empresa.',
      REQUEST_CIEC_PROVIDER: 'Autorización y consentimiento proporcionado con clave CIEC por:',
      REQUEST_CIEC: 'Solicitar clave CIEC',
      SUPPLIER_HELP_TOGGLE: 'Click aquí si el receptor de los fondos será el propio Solicitante.',
      SUPPLIER_HELP: 'Selecciona la Empresa proveedora de los insumos o servicios que recibirá los fondos.',
      TITLE: 'Solicitud de crédito'
    },
    FIELDS_TABLE: {
      ADD_CROP: 'Agregar cultivo',
      ADD: 'Agregar cultivo...',
      CROP: 'Cultivo',
      FIELDS: 'Campos',
      FILE_FISCALID_NOT_FOUND: 'No se encontró el <b>CUIT</b> del Solicitante en el documento subido.',
      FILE_HELP: 'Para agregar cultivos, <b>arrastra y suelta aquí</b> algunos de los formatos de archivo soportados.',
      FILE_WARNING_BUTTON: 'Validar ubicación y características de cada uno',
      FILE_WARNING: 'Se identificaron los siguientes datos en el archivo sumistrado.',
      HECTARES: 'Hectáreas',
      IMPORT_LINK: 'Importar de una fuente externa',
      IMPORT_PRODUCTION_PLAN: 'Importar Planteo Productivo',
      IMPORT_SOURCES: 'Fuentes disponibles',
      IMPORT: 'Importar',
      LATITUDE: 'Lat.',
      LONGITUDE: 'Long.',
      MANUAL_HELP: 'Haz <b>click en el mapa</b> para agregar las ubicaciones de tus cultivos.',
      MAP_HELP: 'Puedes arrastrar el marcador para ajustar las coordenadas de la posición.',
      season: 'Ciclo',
      technology: 'Modalidad',
      TABS: {
        DIFF_HELP: 'Diferencias entre lo declarado y lo obtenido del archivo:',
        DIFF: 'Diferencias',
        SOURCE_HELP: 'Obtenido del archivo proporcionado:',
        SOURCE: 'Fuente',
        SUBMITTED_HELP: 'Declardo por el usuario:',
        SUBMITTED: 'Declarado'
      },
      TYPES: {
        LEASED: 'Campo arrendado',
        OWNED: 'Campo propio'
      }
    },
    STATUS: {
      ERROR: 'Error',
      EXPIRED: 'Caducada',
      GRANTED: 'Otorgado!',
      POTENTIAL_DEFAULT: 'Potencial mora',
      REJECTED: 'Rechazada',
      STEP_APPROVED: '<b>Aprobado!</b> Paso {{index}} de {{total}}.',
      STEP_CURRENT: '<b>En revisión/pendiente.</b> Paso {{index}} de {{total}}.',
      STEP_EXPIRED: 'Esta solicitud se encuentra <b>vencida</b>.',
      STEP_REJECTED: 'Esta solicitud fue <b>rechazada</b>.',
      STEP_TURN: '<i class="material-symbols-rounded blinking">warning</i> <b>Debes completar y revisar este paso.</b> Paso {{index}} de {{total}}.',
      STEP_WITHDRAWN: 'Esta solicitud fue <b>retirada</b>.',
      WITHDRAWN: 'Retirada',
      REPORT: {
        '1': 'Pendiente',
        '2': 'Procesando...',
        '3': 'Listo',
        '4': 'Error'
      }
    },
    CREDIT: {
      FORMULA: 'Fórmula',
      FUNCTION: 'Función',
      RESULT: 'Resultado',
      SUBSTITUTE: 'Reemplazo valores',
      VALUE: 'Valor',
      VARIABLE: 'Variable'
    }
  },
  DASHBOARDS: {
    DELIVERED: 'Entregado',
    DELIVERED_WITHOUT_INVOICED: 'Entregado sin facturar',
    EXPIRED_VOLUME: 'Entregas vencidas',
    IN_CONTRACTS: 'en {{quantity}} Contratos.',
    PENDING: 'Pendiente',
    PENDING_TO_EXPIRE: 'Por vencer',
    TO_BE_FIXED: 'Pendiente de fijación',
    VOLUME_TBF: 'Volumen a fijar',
    VOLUME_TO_EXPIRE: 'Entregas por vencer'
  },
  LABELS: {
    COLOR: 'Color',
    CREATE: 'Crear etiqueta',
    LABEL_AS: 'Etiquetar como:',
    MANAGE: 'Gestionar etiquetas',
    NEW: 'Nueva etiqueta',
    SAMPLE: 'Muestra:',
    SELECTED: 'Elementos seleccionados: <span class="quantity">{{quantity}}</span>'
  },
  STATUS_LABEL: {
    CREATE: 'Crear Estado',
    EDIT: 'Editar Estado',
    NEW: 'Nuevo Estado',
    UPDATE: 'Modificar Estado',
    WARNING: 'Todos los elementos que tengan definido el Estado: <b>{{status}}</b>, quedarán identificados como <b>Sin estado</b>.',
    NONE: 'Sin estado'
  },
  COMMERCIAL_ZONES: {
    CREATE: 'Crear Zona comercial',
    BIND_TO: 'Vincular a:',
    EDIT: 'Editar Zona comercial',
    MANAGE: 'Gestionar Zonas comerciales',
    NEW: 'Nueva Zona comercial',
    NO_UNIQUE: 'Ya existe una Zona comercial con ese nombre.'
  },
  NOT_FOUND: {
    BUTTON: 'Volver a la página principal',
    DESCRIPTION: 'Si fuiste redireccionado a esta página por la plataforma, por favor contacta a nuestro <a href="mailto:info@agree.ag">equipo de soporte</a>.',
    SUBTITLE: 'Esta página no existe',
    TITLE: 'Algo salió mal... :('
  },
  PRESETS: {
    CREATE: 'Guardar nuevo...',
    NEW: 'Nuevo preajuste',
    NO_DATA: 'Aún no hay preajustes.',
    PRIVATE_HELP: 'Un preajuste privado, <b>no es visible</b> para el resto de la Empresa.',
    TITLE: 'Preajustes',
    FILTERS_LABEL: 'Historial de filtros',
    SAVE_FILTERS: 'Guardar filtros',
    SAVE_FILTERS_DESC: 'Al guardar esta configuración de filtros, la próxima vez que accedas a la sección, se aplicarán de forma automática.',
    FILTERS_DELETE_TOOLTIP: 'Borrar configuración',
    FILTERS_DELETE_BODY: '¿Seguro que quiere borrar esta configuración de filtros preajustados?',
    FILTERS_ACTIVE_LABEL: 'Filtros preaplicados <span class="material-symbols-rounded">filter_alt</span>'
  },
  INTEGRATIONS: {
    API_DOCUMENTATION_HELP: 'Para acceder a la documentación de nuestra API, ponte en contacto con nuestro <a href="mailto:info@agree.ag">equipo de soporte</a>.',
    API_DOCUMENTATION: 'Documentación API',
    TITLE: 'Integraciones API',
    MONITOR: 'Monitor de Integraciones API',
    WEBHOOK_MODEL: 'Modelo de payload de webhook',
    WEBHOOKS_HELP: 'Los webhooks son llamadas HTTP POST que se realizan ante determinados eventos. Puedes especificar hasta 5 URLs.',
    WEBHOOKS: 'Webhooks'
  },
  VOLUME: {
    ADDITIONAL_HELP: 'Comisiones, fletes, etc.',
    ADDITIONAL: 'Adicionales',
    AMOUNT: 'Monto a cubrir',
    APPLY: 'Aplicar',
    CALCULATE: 'Calcular',
    CALCULATOR: 'Calculadora de volumen',
    DAYS: 'Dias de duración',
    GRANTING_DATE: 'Fecha de otorgamiento',
    MULTIPLE: 'Volumen (múltiplo de {{multiple}})',
    NEEDED_WARRANTY: 'Volumen necesario + aforo',
    NEEDED: 'Volumen necesario',
    PRICE: 'Precio de referencia',
    SUBTOTAL: 'Subtotal',
    TOTAL_AMOUNT: 'Total (monto + adicionales)',
    VAT_INCLUDED: 'IVA incluido',
    WARRANTY: 'Aforo'
  },
  LIQUIDACIONES: {
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Liquidación(es)',
    TITLE: 'Liquidaciones',
    TIPO: {
      '1': 'Primaria',
      '2': 'Secundaria',
      '3': 'Ajuste'
    }
  },
  DOC_CUSTOM: {
    FOOTER: 'Pie de página',
    HEADER: 'Encabezado',
    HELP: 'Puedes incorporar una leyenda en el encabezado y al pie de los documentos generados por la plataforma donde tu Empresa esté involucrada.',
    INFO: 'Cualquier cambio sólo se verá reflejado en los documentos generados de ahora en adelante.',
    TITLE: 'Personalizar documentos'
  },
  WORKFLOW: {
    GROUPS: {
      account_opening: 'Apertura de cuenta',
      analysis: 'Análisis',
      application_signing: 'Firma de solicitud',
      approval: 'Aprobación',
      axc_contract: 'Contrato AxC',
      bail_signing: 'Firma de fianza',
      branch: 'Sucursal',
      buro_externo: 'Buró externo',
      buro_interno: 'Buró interno',
      cession: 'Cesión',
      collaterals: 'Colaterales',
      committee: 'Comité',
      confirmation: 'Confirmación',
      credit_committee: 'Comité de crédito',
      credit_contract: 'Contrato de crédito',
      doa: 'Revisión',
      documentation: 'Documentación',
      echeq: 'eCheq',
      endorsement: 'Aval',
      granting: 'Otorgamiento',
      invoice: 'Factura',
      liquidation: 'Liquidación',
      portfolio: 'Análisis de Clientes',
      promissory_note: 'Pagaré',
      proof: 'Comprobante',
      signing: 'Firmas',
      site_review: 'Visita de inspección',
      warranty_review: 'Revisión de Garantía'
    },
    FIELD: {
      account_opening_letter: 'Carta de Apertura de Cuenta',
      agriculturalForm: 'Formulario agropecuario',
      alyc: 'ALyC',
      amount: 'Línea de crédito solicitada',
      approved_portfolio: 'Clientes aprobados',
      approvers: 'Aprobadores',
      assembly_minute: 'Acta de Asamblea',
      assembly_minutes: 'Última Acta de Asamblea',
      assessment_certificate: 'Certificado de libertad de gravamen',
      authorities_act: 'Acta de designación de autoridades',
      axc_contract: 'Contrato AxC',
      bail_signers_ids: 'DNI de los firmantes de la fianza',
      bail_signers: 'Firmantes de la fianza',
      bailor_address: 'Dirección del fiador',
      bailor: 'Fiador',
      balance_sheet_approval: 'Acta de aprobación de balances',
      bank_financial_debt: 'Deuda bancaria y financiera',
      bank_statements: 'Estados de cuenta bancarios',
      broker: 'Corredor',
      business_review: 'Reseña del negocio',
      business_supplier_review: 'Reseña del negocio y de la insumera',
      buyer: 'Comprador',
      buyerRFC: 'RFC del Comprador',
      certified_assets_manifestation: 'Manifestación de Bienes certificada',
      cession_notice: 'Notificación de cesión',
      charges_act: 'Acta de distribución de cargos',
      client_concept: 'Concepto del cliente',
      collateral_amount: 'Monto de la garantía',
      commercial_appraisal: 'Avalúo comercial',
      commercialDebt: 'Deuda comercial',
      company_acts: 'Actas de la Empresa',
      constitutive_act: 'Acta constitutiva',
      contract: 'Contrato',
      corporate_composition: 'Composición societaria',
      credit_amount: 'Monto acreditado',
      credit_analysis: 'Análisis crediticio',
      credit_application_form: 'Formulario de Solicitud de Crédito',
      credit_bureau_consultation: 'Consulta de Buró de Crédito',
      current_account_ars: 'Cuenta Corriente AR$',
      current_account_usd: 'Cuenta Corriente USD',
      ddjjBienes: 'DDJJ Bienes personales',
      ddjjGanancias: 'DDJJ Ganancias',
      ddjjIVA: 'DDJJ del IVA',
      debit_note: 'Nota de débito',
      delivery_range: 'Período de entrega',
      due_date: 'Fecha de vencimiento',
      echeq_amount: 'Monto del eCheq',
      echeq: 'eCheq',
      electronic_invoices: 'Factura(s) electrónica(s)',
      FILES: 'Archivo(s)',
      financeableCrop: 'Cultivo financiable',
      financial_statements: 'Estados financieros',
      financialDebt: 'Deuda financiera a largo plazo',
      fiscal_proof: 'Constancia de situación fiscal',
      fwd_contract: 'Contrato forward',
      granting_date: 'Fecha de liquidación',
      guaranteed_amount: 'Monto avalado',
      has_alyc_account: 'Posee cuenta en una ALyC',
      has_rosental_account: 'Posee cuenta en Rosental',
      id_certified_copy: 'Copia certificada de DNI',
      IDBack: 'Dorso del DNI',
      IDFront: 'Frente del DNI',
      iibb: 'Constancia de inscripción IIBB',
      insurance_policy: 'Póliza de seguro',
      investment_project: 'Proyecto/presupuesto de la inversión',
      invoices: 'Factura(s) del insumo/servicio',
      IOU: 'Pagaré',
      issue_date: 'Fecha de emisión',
      iva: 'Constancia de inscripción IVA',
      last_three_balances: 'Últimos tres balances',
      lastBalance: 'Último balance',
      lastTwoBalances: 'Últimos dos balances',
      locality_and_province: 'Localidad y provincia',
      observations: 'Observaciones',
      openingForm: 'Formulario de apertura',
      personalPropertyDeclaration: 'Manifestación de bienes',
      policy_invoices: 'Factura(s) de la(s) póliza(s)',
      promissory_note_amount: 'Monto del Pagaré',
      proof_of_address: 'Comprobante de domicilio',
      property_lease: 'Titulo de Propiedad/Contrato de Arrendamiento',
      real_estate_insurance: 'Seguro del bien inmueble',
      reforms_statutes: 'Reformas a estatutos',
      representative_ids: 'IDs de representantes y apoderados',
      representative_ine: 'Identificación oficial (INE) de representantes legales',
      representative_powers: 'Poderes de representantes legales',
      requires_cession_notice: 'Requiere comprobante de notificación de cesión',
      RPC_enrollment: 'Inscripción en RPC',
      sale_deed: 'Escritura',
      sales_detail: 'Detalle de ventas',
      settle_amount: 'Monto a liquidar',
      settlement_receipt: 'Comprobante de liquidación',
      sgr: 'S.G.R.',
      shareholders_assets_declaration: 'Manifestación de bienes de los socios',
      shareholders_book: 'Libro de accionistas',
      shareholders_credit_bureau_consultation: 'Consulta de Buró de Crédito de accionistas',
      shareholders_ine: 'Identificación oficial (INE) de accionistas',
      shareholders_list: 'Nómina de socios',
      shareholders_proof_of_address: 'Comprobante de domicilio de accionistas',
      shareholding_structure: 'Composición accionaria',
      signers: 'Firmantes',
      sme_certificate: 'Certificado PYME',
      stamp_tax_exemption: 'Constancia de Exención del Impuesto de Sellos',
      statute_modifications: 'Modificaciones al estatuto',
      statute: 'Estatuto de la sociedad',
      supplier_quote: 'Cotización(es) de los proveedores',
      tax_jurisdiction: 'Jurisdicción para Impuestos',
      term: 'Plazo',
      transfer_amount: 'Monto del crédito otorgado',
      transfer_receipt: 'Comprobante de transferencia',
      visit_report: 'Informe de la Visita de Inspección',
      warranty_certificate: 'Certificado de garantía',
      warranty: 'Garantía',
      water_use_concession: 'Concesión de aprovechamiento de agua'
    },
    POSSIBILITY: {
      approve: 'Aprobar',
      confirm: 'Confirmar',
      continue: 'Continuar',
      error: 'Error',
      grant: 'Otorgar',
      reject: 'Rechazar',
      return: 'Volver al paso anterior',
      send: 'Enviar'
    },
    TYPE: {
      0: "WAIT",
      1: "END",
      2: "START",
      3: "TRANSITION",
      4: "FORM",
      5: "REVIEW",
      6: "CHOICE"
    }
  },
  METADATA: {
    IMPORTANT_ONLY: 'Sólo importantes',
    TITLE: 'Datos adicionales'
  },
  DOA: {
    APPROVAL: 'Aprobación',
    LINKED_TO: 'Vinculada a',
    TITLE: 'Aprobaciones',
    WAITING: 'Esperando su decisión...',
    STATUS: {
      APPROVED: 'Aprobada',
      DISAPPROVED: 'Desaprobada',
      PENDING: 'Pendiente',
      RETIRED: 'Retirada'
    }
  },
  FORM_REUSE: {
    HELP: 'Selecciona los campos que quieres reutilizar:',
    REUSE: 'Reutilizar',
    TITLE: 'Reutilizar datos...'
  },
  AUTHORIZATIONS: {
    ADD_MORE: 'Definir nuevo Representante...',
    CREATE: 'Validación de identidad',
    DUE_DATE: 'Vigente hasta',
    FISCAL_PROOF_HELP: 'Adjunta/Sube el archivo emitido por SAT, en formato PDF.',
    FISCAL_PROOF: 'Constancia de situación fiscal',
    MESSAGE_PLACEHOLDER: 'Autorizo/Acepto las siguientes condiciones...',
    MESSAGE: 'Mensaje',
    PROVIDER: 'Validación de identidad realizada a:',
    REPRESENTATIVES: 'Representantes',
    REQUESTED: 'Solicitada',
    ENTITY_HELP: {
      DEFAULT: 'Para solicitar autorización, es necesario subir la <b>CONSTANCIA DE SITUACIÓN FISCAL</b> del Solicitante.',
      FISCAL_ID: 'Para solicitar autorización para el RFC: <samp>{{reference}}</samp>, es necesario subir la <b>CONSTANCIA DE SITUACIÓN FISCAL</b> del Solicitante.',
      FORM: 'Para solicitar autorización para el {{label}}: <samp>{{reference}}</samp>, es necesario completar el siguiente formulario.'
    },
    STATUS: {
      AUTHORIZED: 'Autorizado',
      CANCELED: 'Cancelado',
      PENDING: 'Pendiente'
    }
  },
  NOTES: {
    ADD_COLUMN: 'Agregar columna',
    ADD_COMPANY_HELP: 'Ingrese todos los <b>números de {{fiscal_id}}</b> que quiera agregar.',
    FOR: 'Datos de {{ company }}',
    TYPE: 'Tipo'
  },
  PORTFOLIO: {
    ACCUMULATED_LINE: 'Línea acumulada',
    ADD_HELP: 'Ingresa el numeró de {{label}} de todos los clientes a analizar. Terminado el análisis, tenndrás la posibilidad de incluir más.',
    ADD: 'Agregar clientes',
    FINISH: 'Finalizar análisis',
    NO_DATA: 'Aún no se informaron clientes de <b>{{company}}</b> para analizar.',
    PROCESSED: 'Ya procesados'
  },
  ACCOUNT: {
    ROLES: {
      admin: 'Administrador',
      commercial_with_users: 'Comercial (con usuarios)',
      commercial: "Comercial",
      counterpart_admin: 'Contraparte de Administrador',
      operations: 'Operaciones',
      logistics: 'Logística',
      business_officer: 'Oficial de Negocios',
      superadmin: 'Super Administrador',
      superadmin_readonly: 'Super Administrador (solo lectura)',
      superadmin_writeonly: 'Super Administrador (solo escritura)'
    }
  },
  API_KEY: {
    DESCRIPTION: 'Para hacer uso de nuestros servicios fuera de la plataforma, es necesario contar con una API key.',
    GENERATE: 'Generar API key',
    NO_KEYS: 'No existen API keys disponibles para tu compañía',
    SAMPLE: 'Autenticación mediante API key',
    TITLE: 'API keys',
    STATUS: {
      ACTIVE: 'Activa',
      INACTIVE: 'Inactiva',
      DELETED: 'Borrada'
    }
  },
  CLIENT_CREDENTIALS: {
    TITLE: 'Credenciales de cliente',
    GENERATE: 'Generar credenciales',
    NO_CREDENTIALS: 'No existen credenciales disponibles para tu compañía',
    CREATED: 'Credenciales de cliente creadas',
    OAUTH: {
      TITLE: 'Autoriza la conexión entre <b>{{company}}</b> y tu cuenta de <b>Agree</b>.',
      LABEL: 'Estas de acuerdo de darle acceso a tu cuenta?',
      DESCRIPTION: '¿Estás de acuerdo en darle acceso a tu cuenta? Permite que <b>{{company}}</b> acceda a tu cuenta de Agree y disfruta de una experiencia integrada y mejorada. Tu información estará segura y utilizaremos únicamente los datos necesarios.',
      REDIRECT_MESSAGE: 'Autorizar te redirigirá a {{url}}',
      AUTHORIZE: 'Autorizar',
      REDIRECT_TO: 'Si autorizas seras redirigido a <u>{{url}}</u>',
      REDIRECTING: 'Redirigiendo...'
    }
  },
  ADMIN_CIEC_REQUESTS: {
    HEADER_LINK: 'Administrador de solicitudes CIEC',
    TABLE_TITLE: 'Administrador de solicitudes CIEC',
    TABLE_DESCRIPTION: 'Observa el estado de las solicitudes',
    RESEND_REQUEST: 'Reenviar solicitud',
    VALIDITY_COLUMN: 'Estado de la CIEC',
    STATUS_COLUMN: 'Estado de la solicitud',
    MODAL: {
      BODY: 'Si la solicitud ha estado pendiente durante un período prolongado, se recomienda enviar nuevamente el correo electrónico.'
    },
    STATUS: {
      UNKNOWN: 'Desconocido/a',
      PENDING: 'Pendiente',
      VALID: 'Valida',
      INVALID: 'Invalida',
      ERROR: 'Error',
      DEACTIVATED: 'Desactivada',
      COMPLETED: 'Completada'
    }
  },
  NIP: {
    ADMIN_TITLE: 'Administrador de solicitudes NIP',
    PROVIDER: 'Autorización y consentimiento proporcionado con firma NIP por:',
    REQUEST: 'Solicitar firma NIP',
    REQUEST_HELP: 'El <b>Solicitante debe prestar autorización de consulta de reporte de crédito</b> mediante su firma NIP. Este código le será enviado por correo electrónico.',
    REQUIRED: 'Es necesaria la <b>autorización mediante firma NIP</b> del Solicitante o Representante legal.'
  },
  ADMIN_TENANTS: {
    HEADER_LINK: 'Administrador de Tenants',
    TABLE_DESCRIPTION: 'Administra los multiples tenants de la plataforma',
  },
  EPYME: {
    STATUS: {
      canceled: 'Cancelada',
      completed: 'Completado',
      expired: 'Vencida',
      failed: 'Error',
      issued: 'Emitido',
      pending: 'Pendiente',
      rejected: 'Rechazada'
    }
  }
};
