<section class="landing-bg">
  <div class="background-images">
    <div class="field w40"></div>
    <div class="teamwork w25"></div>
  </div>
  <div class="overlay"></div>
  <img class="dots" src="/assets/img/home/dots.png" />
  <div class="gradient-circle"><img src="/assets/img/t.png" /></div>
</section>

<section class="text-container">
  <div class="container-fluid">
    <div class="row header">
      <div class="col-xs-12 flex-center-distributed">
        <a class="logo" [routerLink]="['/']"></a>
        <div class="text-right">
          <a
            class="white"
            routerLink="/sign-up"
            [innerHtml]="'LOGIN.DONT_HAVE_ACCOUNT' | translate"
          ></a>
          <span class="widther">&bull;</span>
          <span dropdown>
            <a
              href
              id="lang"
              dropdownToggle
              (click)="(false)"
              class="white text-uppercase small"
              aria-controls="lang-dropdown"
              >{{ translateService.currentLang }}</a
            >
            <ul
              id="lang-dropdown"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="lang"
            >
              <li
                *ngFor="let lang of availableLanguages"
                [class.hidden]="translateService.currentLang === lang.slug"
              >
                <a (click)="updateLanguage(lang.slug)">{{ lang.name }}</a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 welcome-message text-center">
        <div
          class="main hidden-xs"
          [innerHtml]="'LANDING.MAIN' | translate"
        ></div>
        <p
          class="secondary brand-color"
          [innerHtml]="'LANDING.SECONDARY' | translate"
        ></p>
        <p
          class="tagline hidden-xs"
          [innerHtml]="'LANDING.TAGLINE' | translate"
        ></p>
      </div>
      <div class="col-md-1 curve">
        <svg
          class="visible-xs visible-sm"
          height="20"
          width="100%"
          viewBox="0 0 100 10"
          preserveAspectRatio="none"
        >
          <path d="M 0 0 H 100 C 70 10, 30 10, 0 0"></path>
        </svg>
      </div>
      <div class="col-md-4">
        <div class="content-box">
          <form
            class="form-signin"
            method="post"
            (ngSubmit)="loginForm.valid && login()"
            #loginForm="ngForm"
          >
            <h3 [innerHtml]="'LOGIN.HEADING' | translate"></h3>

            <alert type="danger" [dismissible]="true" *ngIf="loginError">
              <span [innerHtml]="'LOGIN.ERROR' | translate"></span>
            </alert>

            <div class="form-group" hasError>
              <label for="inputEmail" class="sr-only">
                {{ "LOGIN.EMAIL" | translate }}
              </label>
              <input
                [disabled]="sending"
                id="inputEmail"
                type="email"
                class="form-control input-lg"
                placeholder="{{ 'LOGIN.EMAIL' | translate }}"
                autofocus
                name="email"
                [(ngModel)]="user.email"
                required
                email
              />
              <span
                class="glyphicon glyphicon-remove form-control-feedback"
                aria-hidden="true"
              ></span>
              <error-list></error-list>
            </div>
            <div class="form-group" hasError>
              <label for="inputPassword" class="sr-only">
                {{ "LOGIN.PASSWORD" | translate }}
              </label>
              <input
                [disabled]="sending"
                id="inputPassword"
                type="password"
                class="form-control input-lg"
                placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
                name="password"
                [(ngModel)]="user.password"
                required
                minlength="6"
              />
              <span
                class="glyphicon glyphicon-remove form-control-feedback"
                aria-hidden="true"
              ></span>
              <error-list></error-list>
            </div>
            <!-- <div class="checkbox">
              <label>
                <input type="checkbox" value="remember-me"> Remember me
              </label>
            </div> -->
            <button
              class="btn btn-lg btn-agree btn-block"
              type="submit"
              [disabled]="sending"
            >
              {{ "LOGIN.LOGIN" | translate }}
            </button>
          </form>
          <ng-container *ngIf="clientGoogleAuth">
            <div class="divider-break">
              <span>{{ "LOGIN.OTHER_OPTIONS" | translate }}</span>
            </div>
            <div
              class="signin-button google"
              (click)="siginWithGoogle()"
              [class.disabled]="sending"
            >
              <div class="icon"></div>
              <div class="legend">{{ "LOGIN.GOOGLE" | translate }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <footer class="text-center small">
    <a class="white" routerLink="/reset-password">{{
      "LOGIN.FORGOT_PASSWORD" | translate
    }}</a>
    <span class="widther">&bull;</span>
    <a class="white" routerLink="/links/terms">{{
      "GLOBAL.TERMS_AND_CONDITIONS" | translate
    }}</a>
    <span class="widther">&bull;</span>
    <a class="white" routerLink="/links/privacy">{{
      "GLOBAL.PRIVACY_POLICY" | translate
    }}</a>
    <span class="widther">&bull;</span>
    <a class="white" href="/links/cookies">Cookies</a>
  </footer>
</section>
